import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { formatCurrency, formatDate } from '@omnicar/sam-format'
import { ISubscription, PriceSpecification } from '@omnicar/sam-types'
import cn from 'classnames'
import { withContractDisplayConfig } from 'components/ContractDisplayConfig/withContractDisplayConfig'
import Typography from 'components/Typography'
import React from 'react'
import { compose } from 'recompose'
import { t, tCurrency } from 'translations/translationFunctions'
import { IContractDisplayConfig } from 'reducers/contractDisplayConfig/initialState'

const styles = ({ spacing, palette, typography }: Theme) =>
  createStyles({
    sectionItem: { display: 'flex', justifyContent: 'space-between' },
    sectionItemLabel: { marginRight: spacing(2) },
    sectionItemValue: {
      fontWeight: typography.fontWeightMedium,
      whiteSpace: 'nowrap',
    },
    priceHeader: {
      color: palette.secondary[600],
      fontWeight: typography.fontWeightMedium,
      marginBottom: spacing(2),
    },
    priceSubheader: {
      marginBottom: spacing(1),
      fontSize: 18,
    },
  })

interface IOwnProps {
  downpayment: PriceSpecification
  subscriptions: ISubscription[]
  minimumPaymentsCount: number | null
  totalAmount: PriceSpecification | null
  minimumTotalAmount: PriceSpecification | null
}

type TProps = IOwnProps & WithStyles<typeof styles> & IContractDisplayConfig

class BillingSummary extends React.Component<TProps> {
  public render() {
    const { classes, downpayment, subscriptions, totalAmount, minimumTotalAmount, hiddenVAT } = this.props

    const subscription = subscriptions[0]

    // Due to an error in calculation module subscription.numberOfPayments sometimes is a non-integer number
    // To fix this temporarily:
    // If it differs < 0.05 from an integer then round the number
    // If it diffs more then hide number of payments and last payment date
    let numberOfPayments = Math.round(subscription.numberOfPayments)
    let showNumberOfPayments = false
    let monthlyAmountLabel: string
    if (Math.abs(numberOfPayments - subscription.numberOfPayments) <= 0.5) {
      monthlyAmountLabel = t('Subscription %numOfPayments payments each of', { numOfPayments: numberOfPayments })
      showNumberOfPayments = true
    } else {
      monthlyAmountLabel = t(hiddenVAT ? 'Price per month' : 'Price per month incl. VAT')
      showNumberOfPayments = false
    }

    // Make sure the total price shows the monthly price x nPayments, even if due to rounding errors it would look otherwise
    const pricePPaymentInclVAT = Math.round(subscription.amountPrPayment.priceInclVat * 100) / 100
    subscription.amountPrPayment.priceInclVat = pricePPaymentInclVAT
    totalAmount && (totalAmount.priceInclVat = downpayment.priceInclVat + numberOfPayments * pricePPaymentInclVAT)
    this.calcMiminimumTotalAmountRounded(minimumTotalAmount, pricePPaymentInclVAT)

    return (
      <>
        <Typography className={cn(classes.sectionItem, classes.priceHeader)} variant="title">
          <span className={classes.sectionItemLabel}>{t('To pay now')}:</span>
          <span className={classes.sectionItemValue}>{this.renderPaymentValue(downpayment)}</span>
        </Typography>
        <Typography className={cn(classes.sectionItem, classes.priceSubheader)} variant="title">
          <span className={classes.sectionItemLabel}>{monthlyAmountLabel}:</span>
          <span className={classes.sectionItemValue}>{this.renderPaymentValue(subscription.amountPrPayment)}</span>
        </Typography>
        <Typography className={classes.sectionItem}>
          <span className={classes.sectionItemLabel}>{t('First monthly payment')}:</span>
          <span className={classes.sectionItemValue}>{formatDate(subscription.firstPaymentDate)}</span>
        </Typography>
        {showNumberOfPayments && (
          <Typography className={classes.sectionItem}>
            <span className={classes.sectionItemLabel}>{t('Last monthly payment')}:</span>
            <span className={classes.sectionItemValue}>{formatDate(subscription.lastPaymentDate)}</span>
          </Typography>
        )}
        {minimumTotalAmount && (
          <Typography className={classes.sectionItem}>
            <span className={classes.sectionItemLabel}>{t('The minimum total amount will be')}:</span>
            <span className={classes.sectionItemValue}>{this.renderPaymentValue(minimumTotalAmount)}</span>
          </Typography>
        )}
        {totalAmount && (
          <Typography className={classes.sectionItem}>
            <span className={classes.sectionItemLabel}>{t('The total amount will be')}:</span>
            <span className={classes.sectionItemValue}>{this.renderPaymentValue(totalAmount)}</span>
          </Typography>
        )}
      </>
    )
  }

  private calcMiminimumTotalAmountRounded(minimumTotalAmount: PriceSpecification | null, monthlyPayment: number) {
    if (!minimumTotalAmount) return
    const nMonths = Math.round(minimumTotalAmount.priceInclVat / monthlyPayment)
    minimumTotalAmount.priceInclVat = nMonths * monthlyPayment
  }

  private renderPaymentValue = (p: PriceSpecification) =>
    `${formatCurrency(p.priceInclVat)} ${tCurrency(p.currency.toUpperCase())}`
}

export default compose<TProps, IOwnProps>(withContractDisplayConfig, withStyles(styles))(BillingSummary)

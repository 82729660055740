import { WithStyles, withStyles } from '@material-ui/core'
import { formatCurrency, formatDate, formatMileage, formatNumber } from '@omnicar/sam-format'
import { ContractState, IApiVehicleContract } from '@omnicar/sam-types'
import { contractPdfUrl } from 'api/api'
import { withContractDisplayConfig } from 'components/ContractDisplayConfig/withContractDisplayConfig'
import React from 'react'
import { compose } from 'recompose'
import { t, tCurrency } from 'translations/translationFunctions'
import { valueTypeTranslations } from 'translations/ValueTypeTranslations'
import DownloadPDFButton from './DownloadPdfButton'
import styles from './styles'
import { IContractDisplayConfig } from 'reducers/contractDisplayConfig/initialState'

type TProps = IOwnProps & WithStyles<typeof styles> & IContractDisplayConfig
interface IOwnProps {
  contract: IApiVehicleContract
  hiddenPriceSection: boolean
}

const CustomerContractDetailsInfo: React.SFC<TProps> = ({ classes, contract, hiddenVAT, hiddenPriceSection }) => {
  const priceFormatted = `${formatCurrency(contract.amount.priceInclVat)} ${tCurrency(contract.amount.currency)}`
  const valueType = contract.valueType ? valueTypeTranslations[contract.valueType].shortValue : undefined
  const value =
    contract.value && contract.valueType === 'Mileage'
      ? formatMileage(contract.value || contract.mileage)
      : formatValue(contract.value)
  const durationFormatted = `${contract.duration} ${t('Month')} ${valueType && value ? `/ ${value} ${valueType}` : ''}`

  return (
    <div className={classes.root}>
      <div className="CustomerContractDetailsInfo__main">
        {!hiddenPriceSection && (
          <div>
            <span className={classes.label}>{t(hiddenVAT ? 'Price per month' : 'Price per month incl. VAT')}</span>
            <span>{priceFormatted}</span>
          </div>
        )}
        <div>
          <span className={classes.label}>{t('Duration')}</span>
          <span>{durationFormatted}</span>
        </div>
        <div>
          <span className={classes.label}>{t('Start Date')}</span>
          <span>{formatDate(contract.contractStartDate)}</span>
        </div>
        <div>
          <span className={classes.label}>{t('End Date')}</span>
          <span>{formatDate(contract.contractEndDate)}</span>
        </div>
      </div>
      <div className={classes.linksList}>
        {contract.contractState !== ContractState.ActivePrePaid && (
          <DownloadPDFButton
            pdfHref={contractPdfUrl(contract.prettyIdentifier)}
            datae2e={'CustomerContractDetailsInfo__main-contract'}
            title={'Contract'}
          />
        )}
        <DownloadPDFButton
          pdfHref={contract.tosUrl}
          datae2e={'CustomerContractDetailsInfo__main-terms'}
          title={'Terms'}
        />
        {contract.totUrl && (
          <DownloadPDFButton
            pdfHref={contract.totUrl}
            datae2e={'CustomerContractDetailsInfo__main-terms-of-trade'}
            title={'Terms of Trade'}
          />
        )}
        {!!contract.serviceInfoPDFUrl &&
          [ContractState.Active, ContractState.ActivePendingTermination].includes(contract.contractState) && (
            <DownloadPDFButton
              pdfHref={contract.serviceInfoPDFUrl}
              datae2e={'CustomerContractDetailsInfo__main-service-info'}
              title={'Service info'}
            />
          )}
      </div>
    </div>
  )
}

function formatValue(num: number | undefined) {
  return num ? formatNumber(num, false) : undefined
}

export default compose<TProps, IOwnProps>(withContractDisplayConfig, withStyles(styles))(CustomerContractDetailsInfo)

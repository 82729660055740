import { formatDate } from '@omnicar/sam-format'
import { IJsonStatus, IRequestParams, requestJson } from '@omnicar/sam-tfetch'
import {
  ApiError,
  ContractListOrderByType,
  ContractOrderBy,
  CustomerListOrderByType,
  CustomerUpdateResponse,
  FuelType,
  IAdminContractActivationResponse,
  IAdminContractAdjustmentResponse,
  IAdminContractOfferCopyResponse,
  IAdminCustomer,
  IAdminCustomerLoginRequest,
  IAdminCustomTermsRecord,
  IAdminUserInfo,
  IContractChartsFilterParams as IAPIContractChartsFilterParams,
  IBasicContractInfo,
  IContractActionResponse,
  IContractAdjustmentRequest,
  IContractBalanceStatistics,
  IContractCalculationResponse,
  IContractCreationData,
  IContractCreationResponse,
  IContractDetailsRecord,
  IContractFilterOptions,
  IContractFilterParams,
  IContractListRecord,
  IContractOfferPrintData,
  IContractPrintCreationRequest,
  IContractProductTypeCountResponse,
  IContractStatusCount,
  IContractTemplateResponse,
  ICreateDelaerPaidContractRequest,
  ICreateDelaerPaidContractResponse,
  ICreditCard,
  ICreditCardRecord,
  ICustomContractCalculationRequest,
  ICustomContractCreationRequest,
  IDashboardCharts,
  IDashboardContractChartsResponse,
  IDashboardContractChartViews,
  IDashboardQueryParams,
  IFuelTypeRequest,
  IInvoice,
  ILoginRequest,
  ILoginResponse,
  InvoiceOrderBy,
  IPaginatedQueryParams,
  IPaginatedResponse,
  IPaymentInformationResponse,
  IPaymentMileageCharts,
  IPreparePaymentRequest,
  IPreparePaymentResponse,
  IProviderStats,
  IPublicKeyResponse,
  IRegistrationNumberResponse,
  IReportDefinition,
  IReportDefinitionsResponse,
  IReportSearchRequest,
  IReportSearchResponse,
  IResetPasswordRequest,
  ISetPaymentMethodRequest,
  ISetPaymentMethodResponse,
  ISettlementActionRequest,
  ISettlementActionResponse,
  ISettlementPaymentRequest,
  ISetupIntentResponse,
  IsoLocale,
  IStandardAxContractCalculationRequest,
  IStandardContractCalculationRequest,
  IStandardContractCreationRequest,
  IStandardV4PricingToolContractCalculationRequest,
  IStripeTOSAcceptance,
  ITableUserConfiguration,
  ITemplateLookupRequest,
  IUserListRecord,
  IUserPasswordChangeRequest,
  IUserRoleChangeRequest,
  IWarrantyFilterParams,
  IWarrantyListRecord,
  IWorkshopOperation,
  IWorkshopOperationCollection,
  Other,
  ProductAlongItsContracts,
  TableConfigurationType,
  TActionOrderBy,
  UserConfiguration,
  UserConfigurations,
  UserConfigurationType,
  UserListOrderByType,
  UserRoleRecord,
  Vehicle,
  VehicleContracts,
  WarrantyOrderBy,
  WorkshopOrderBy,
} from '@omnicar/sam-types'
import { IDashboardFilterParams } from '@omnicar/sam-types/types/admin/chart/dashboardCharts'
import { IContactInformation } from '@omnicar/sam-types/types/admin/contactInformation'
import { IInternalContractNoteResponse } from '@omnicar/sam-types/types/admin/contract/internalContractNotes'
import { ContractProviderOrderBy, IContractProviderInfo } from '@omnicar/sam-types/types/admin/contractProvider'
import { IApiOtherWithContracts, TContractObject } from '@omnicar/sam-types/types/admin/product/product'
import {
  ICustomerPasswordChangeRequest,
  IOutageResponse,
  IPaymentFailureReason,
  IProviderWithContracts,
  IReleaseNotesResponse,
  IReleaseVersionResponse,
  ITemplatesVehicleLookupRequest,
} from '@omnicar/sam-types/types/index'
import { SentryEvent, Severity } from '@sentry/types'
import { setReduxOutage } from 'actions/outageActions'
import { CONFIG } from 'config'
import { BlacklistedVehicleOrderByType } from 'pages/admin/AdministrationPage/Tabs/Blacklist'
import { RoleProvider } from 'pages/admin/AdministrationPage/Tabs/Blacklist/BlacklistComponent'
import { IBlacklistedVehicle } from 'pages/admin/AdministrationPage/Tabs/Blacklist/BlacklistInput'
import { IPasswordValidation } from 'pages/LogInPage/LogInPanel/Authenticate'
import qs from 'qs'
import { clearTokens, handleRefreshToken } from 'utils/auth'
import { getRefreshToken } from 'utils/cookies'
import { getAuth, getToken, getUserInfo, setAuth } from 'utils/localStorage'
import notify, { ErrorType } from 'utils/notify/notify'
import { captureEvent } from 'utils/sentry'
import store from 'utils/store'

// Courtesy function to provide leading path to API
export const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}`

const appPrefix = 'admin'
const customerPortalPrefix = `${appPrefix}/portal`
const v1Prefix = 'v1'
const reportsPrefix = 'reports'

const tableConfigProductTypeMapping: Record<TContractObject, TableConfigurationType> = {
  Product: 'productServiceContract',
  Vehicle: 'serviceContract',
}

const CHANGE_ROLE_PATH = '/change-role'

let millisOld: number = 0 // Used by patchAndRefetchImportantData(..).

function handleErrorAlerts<T, E>(response: IJsonStatus<T, E>, url: string, payload?: any) {
  const { errorData, networkError, statusCode } = response

  let errorType: ErrorType | undefined
  let message: string = ''
  // If no connection to server we need to announce this to the user
  if (networkError) {
    // Since some browsers classify the connection as "online"
    // in different ways, using the offline value instead of online we
    // can be more accurate in our assumption
    if (!window.navigator.onLine) {
      errorType = networkError
    }
  }

  // Error data means we have validation problems
  if (errorData) {
    // assumption: errorData is always 'ApiError'
    errorType = ((errorData as unknown) as ApiError).message
    message = ((errorData as unknown) as ApiError).message
  }

  if (errorType === 'GENERIC_OUTAGE' && errorData && 'params' in errorData) {
    const outage = ((errorData as unknown) as {
      params: IOutageResponse
    }).params

    setOutageFromResponse(outage, errorType)

    return false
  }

  // Status code situations - for now anything over 400 means record not found
  if (statusCode && statusCode >= 500) {
    errorType = 'GENERIC_ERROR'
  }
  if (errorType) {
    const extra: Record<string, any> = {}
    const event: SentryEvent = { level: Severity.Error, message: errorType }
    const auth = getAuth()
    if (auth && auth.providerInfo) {
      extra.providerId = auth.providerInfo.providerId
    }
    extra.url = url
    if (payload) {
      extra.payload = payload
    }
    event.extra = extra
    if (errorType === 'GENERIC_ERROR') captureEvent(event)
    if (statusCode && statusCode === 499) {
      notify.warning({ message, errorType })
    } else {
      notify.error({ errorType })
    }
    return false
  }

  return true
}

// Using requestJson from "tfetch" by Erik Beuschau - https://github.com/OmniCar/tfetch
export interface IApiRequest<B> extends IRequestParams<B> {
  body?: B
  disableErrorHandling?: boolean
}

function setOutageFromResponse(outage: IOutageResponse, errorType: ErrorType | undefined) {
  // sets outage
  store.dispatch(setReduxOutage(outage))

  // For test. might bring this back in the future
  // switch (outage.severityLevel) {
  //   case SeverityLevel.Low:
  //     notify.info({ message: outage.message || t('Unknown outage. Please try again in a moment.'), errorType })
  //     break
  //   case SeverityLevel.Medium:
  //     notify.warning({ message: outage.message || t('Unknown outage. Please try again in a moment.'), errorType })
  //     break
  //   case SeverityLevel.High:
  //     notify.error({ message: outage.message || t('Unknown outage. Please try again in a moment.'), errorType })
  //     break
  // }
}

export async function apiRequest<B, T>(
  options: IApiRequest<B>,
  hideError?: boolean,
  isRefreshTokenRequest?: boolean,
): Promise<IJsonStatus<T, ApiError>> {
  const { disableErrorHandling, url, ...rest } = { disableErrorHandling: false, timeout: 600 * 1000, ...options }

  // Add access token and a unique id that identifies the call
  const uniqueValue = Math.random().toString(36).substr(2, 10)
  const extraHeaders = options.extraHeaders || [
    { key: 'access-token', value: getToken() || '' },
    { key: 'callid', value: uniqueValue },
  ]

  const config: IRequestParams<B> = { url: `${baseUrl}/${url}`, extraHeaders, ...rest }
  let response: IJsonStatus<T, ApiError> = await requestJson<T, ApiError>(config)
  // intercept invalid token
  if (response.statusCode === 403 && response.errorData?.message === 'GENERIC_INVALID_TOKEN') {
    const allowRetry = await interceptInvalidToken(isRefreshTokenRequest)
    // retry request
    response = allowRetry
      ? await requestJson<T, ApiError>({
          ...config,
          // we must use new access-token
          extraHeaders: extraHeaders?.map((h) => (h.key === 'access-token' ? { ...h, value: getToken() || '' } : h)),
          // if change role request, we must send new refresh-token
          body: config.url.includes(CHANGE_ROLE_PATH)
            ? { ...config.body, token: getRefreshToken() || '' }
            : config.body,
        })
      : response
  }

  if (
    !disableErrorHandling &&
    (response.networkError || response.errorData) &&
    !shouldHideError(response, response.statusCode === 403 || !!hideError)
  ) {
    handleErrorAlerts<T, ApiError>(response, config.url, config.body)
  }

  let result = response

  if (response && 'data' in response && typeof response.data === 'object' && isOutageResponse<T>(response)) {
    if (response.data.outage !== null && 'headerText' in response.data.outage) {
      store.dispatch(setReduxOutage(response.data.outage))
    } else if (response.data.outage === null) {
      store.dispatch(setReduxOutage(null))
    }
    result = { statusCode: response.statusCode, data: response.data.body }
  }

  return result
}
// TODO: figure out how to change 'outage: any' to specific type
const isOutageResponse = <T>(response: any): response is { data: { outage: any; body?: T } } =>
  'outage' in response.data

/**
 * @param isRefreshTokenRequest
 */
const interceptInvalidToken = async (isRefreshTokenRequest?: boolean): Promise<boolean> => {
  let tokenRefreshed = false

  if (isRefreshTokenRequest) {
    clearTokens()
  } else {
    await handleRefreshToken()
    tokenRefreshed = true
  }
  return tokenRefreshed
}

function shouldHideError(response: any, hideError: boolean) {
  // const isNotProduction = envIsNotProduction()
  // removed isNotProduction check because we don't want to show errors in production
  const shouldHideError = !!hideError && !response.networkError

  return shouldHideError
}

// function envIsNotProduction() {
//   return process.env.REACT_APP_ENVIRONMENT === 'development' || process.env.REACT_APP_ENVIRONMENT === 'staging'
// }

interface IResponseData {
  message: string
}

async function handleFetch(input: RequestInfo | string, init?: RequestInit) {
  try {
    let res = await fetch(input, { ...init, headers: { 'access-token': getToken() || '' } })
    const invalidToken = res.status === 403 && (await res.json()).message === 'GENERIC_INVALID_TOKEN'
    // intercept invalid token
    if (invalidToken) {
      const allowRetry = await interceptInvalidToken()
      // retry request
      // we must use new access-token
      res = allowRetry ? await fetch(input, { ...init, headers: { 'access-token': getToken() || '' } }) : res
    }

    if (res && !res.ok) {
      const parsedResponse: IResponseData = await res.json()
      //const message = parsedResponse && parsedResponse.message
      //notify.error({ message: message || '' })
      parsedResponse && notify.error({ message: (parsedResponse as any).debug.message || '' })
      console.error(res.statusText)
    }

    return res
  } catch (error) {
    //notify.error()
    if (error instanceof Error) {
      console.error(error.message || error)
    }
  }

  return
}

export const patchAndRefetchImportantData = async (providerId: number) => {
  const serverRefetchDataThresholdMillis: number = CONFIG.serverRefetchDataThresholdMillis
  const millisNow: number = Date.now()

  if (millisNow > millisOld + serverRefetchDataThresholdMillis) {
    const oldAuth: IPasswordValidation | undefined = getAuth()

    if (!oldAuth) {
      console.warn('Failed to find authentication')
    } else {
      const response: any = await getContractProviderInfo(providerId)

      if (!response?.data || response.statusCode !== 200) {
        console.warn('Failed to refetch provider info')
      } else {
        const providerInfo: IContractProviderInfo = response.data

        const updatedAuth: IPasswordValidation = {
          ...oldAuth,
          providerInfo: providerInfo,
        }

        setAuth(updatedAuth)
      }
    }

    doTouchUser()
    millisOld = millisNow
  }
}

export function doTouchUser(): void {
  const userInfo: IAdminUserInfo | undefined = getUserInfo()
  if (userInfo?.email) {
    touchUser(userInfo.email)
  }
}

/********************************************************
 * FreeWarranty API Requests
 ********************************************************/

/**
 * createOrSignVersion of creating a Dealer-Paid warranty version "Ver2-SignDealerPaidContract".
 * NOTE: Can create any contracts (both warranties and service agreements) provided by V4PricingTool.
 */
export function signDealerPaidWarranty(body: ICreateDelaerPaidContractRequest) {
  return apiRequest<ICreateDelaerPaidContractRequest, ICreateDelaerPaidContractResponse>({
    url: `${appPrefix}/v4pt-contract/sign-dealer-paid`,
    method: 'POST',
    body,
  })
}

//*******************************************************

/********************************************************
 * Contract API Requests
 ********************************************************/

export function getContracts(request: IPaginatedQueryParams<ContractOrderBy, IContractFilterParams>) {
  doTouchUser()

  const query = qs.stringify(request)
  const url = `${appPrefix}/contracts?${query}`

  return apiRequest<
    IPaginatedQueryParams<ContractOrderBy, IContractFilterParams>,
    IPaginatedResponse<IContractListRecord[], ContractOrderBy>
  >({
    url,
    method: 'GET',
  })
}

// Get contract count by contractObjectType,
// a count for each product type is returned if no product type is given
export function getContractCount(contractObjectTypes?: TContractObject[]) {
  const query = qs.stringify({ contractObjectTypes })
  const url = `${appPrefix}/contract-product-count${contractObjectTypes ? `?${query}` : ''}`
  return apiRequest<TContractObject[] | never, IContractProductTypeCountResponse | undefined>({
    url,
    method: 'GET',
  })
}

export function getWarranties(request: IPaginatedQueryParams<WarrantyOrderBy, IWarrantyFilterParams>) {
  const query = qs.stringify(request)
  const url = `${appPrefix}/warranties?${query}`

  return apiRequest<
    IPaginatedQueryParams<WarrantyOrderBy, IWarrantyFilterParams>,
    IPaginatedResponse<IWarrantyListRecord[], WarrantyOrderBy>
  >({
    url,
    method: 'GET',
  })
}

export function getContractSpecifics(prettyIdentifier: string) {
  return apiRequest<never, IContractDetailsRecord>({
    url: `${appPrefix}/contracts/${prettyIdentifier}/details`,
    method: 'GET',
  })
}

export function getContractBasicInfo(prettyIdentifier: string) {
  doTouchUser()
  return apiRequest<never, IBasicContractInfo>({
    url: `${appPrefix}/contracts/${prettyIdentifier}/basic-info`,
    method: 'GET',
  })
}

export function getContractProduct(prettyIdentifier: string) {
  return apiRequest<never, Vehicle | Other>({
    url: `${appPrefix}/contracts/${prettyIdentifier}/vehicle`,
    method: 'GET',
  })
}

export interface VehicleUpdateResponse extends Vehicle {
  extSysRegNumberUpdateMessage?: string
}

export function patchContractProduct(prettyIdentifier: string, record: Partial<Vehicle | Other>) {
  return apiRequest<Partial<Vehicle | Other>, VehicleUpdateResponse | Other>({
    url: `${appPrefix}/contracts/${prettyIdentifier}/product`,
    method: 'PATCH',
    body: record,
  })
}

export function getContractCustomer(contractPrettyIdentifier: string) {
  return apiRequest<never, IAdminCustomer>({
    url: `${appPrefix}/contracts/${contractPrettyIdentifier}/customer`,
    method: 'GET',
  })
}

export function patchContractCustomerData(contractPrettyIdentifier: string, record: Partial<IAdminCustomer>) {
  return apiRequest<Partial<IAdminCustomer>, CustomerUpdateResponse>({
    url: `${appPrefix}/contracts/${contractPrettyIdentifier}/customer-data`,
    method: 'PATCH',
    body: record,
  })
}

/**
 * Change the customer/owner of a contract to an existing customer.
 *
 * @param toNewCustomerId The new owner, the customer must already exist.
 * @param currentCustomerId The old/current owner has to be confirmed as an extra layer of
 *        protection. This id must match with the current on the contract.
 */
export function changeContractCustomerToExistingCustomer(
  contractPrettyIdentifier: string,
  toNewCustomerId: number,
  currentCustomerId: number,
) {
  return apiRequest<{ toNewCustomerId: number; currentCustomerId: number }, CustomerUpdateResponse>({
    url: `${appPrefix}/contracts/${contractPrettyIdentifier}/to-existing-customer`,
    method: 'POST',
    body: { toNewCustomerId: toNewCustomerId, currentCustomerId: currentCustomerId },
  })
}

/**
 * Change the customer/owner of a contract to an entirely NEW customer.
 *
 * @param toNewCustomer The customer MUST NOT exist, it will be created.
 */
export function changeContractCustomerToEntireNewCustomer(
  contractPrettyIdentifier: string,
  toNewCustomer: IAdminCustomer,
) {
  return apiRequest<IAdminCustomer, CustomerUpdateResponse>({
    url: `${appPrefix}/contracts/${contractPrettyIdentifier}/to-new-customer`,
    method: 'POST',
    body: toNewCustomer,
  })
}

export function getContractActionList(prettyIdentifier: string, request: IPaginatedQueryParams<TActionOrderBy>) {
  const query = qs.stringify(request)
  const url = `${appPrefix}/contracts/${prettyIdentifier}/actions?${query}`

  return apiRequest<
    IPaginatedQueryParams<TActionOrderBy>,
    IPaginatedResponse<IContractActionResponse[], TActionOrderBy>
  >({
    url,
    method: 'GET',
  })
}

export function getContractWorkshopOperationList(prettyIdentifier: string) {
  const url = `${appPrefix}/contracts/${prettyIdentifier}/workshopoperations`

  return apiRequest<IPaginatedQueryParams<WorkshopOrderBy>, { result: IWorkshopOperationCollection[] }>({
    url,
    method: 'GET',
  })
}

export function postContractWorkshopOperation(prettyIdentifier: string, record: IWorkshopOperation) {
  return apiRequest<IWorkshopOperation, IWorkshopOperation>({
    url: `admin/contracts/${prettyIdentifier}/workshopoperations`,
    method: 'POST',
    body: record,
  })
}

export function updateContractWorkshopOperation(id: number, record: IWorkshopOperation) {
  return apiRequest<IWorkshopOperation, IWorkshopOperation>({
    url: `admin/workshopoperations/${id}`,
    method: 'PUT',
    body: record,
  })
}

export function deleteContractWorkshopOperation(id: number) {
  return apiRequest<IWorkshopOperation, IWorkshopOperation>({
    url: `admin/workshopoperations/${id}`,
    method: 'DELETE',
  })
}

// @TODO: using 'raw' fetch because tfetch has problems with file upload atm...
export function uploadContractWorkshopOperationFile(
  filetype: string,
  contractObjectType: TContractObject,
  body: FormData,
) {
  return handleFetch(`${baseUrl}/admin/workshopoperations/${filetype}/${contractObjectType}`, {
    method: 'POST',
    body,
  })
}

export function getValidHeadersFoWorkshopUpload(locale: IsoLocale) {
  return apiRequest<never, Record<TContractObject, string[]>>({
    url: `admin/workshopoperations/valid-import-headers/${locale}`,
    method: 'GET',
  })
}

export function getContractInvoiceList(
  prettyIdentifier: string,
  request: IPaginatedQueryParams<InvoiceOrderBy>,
  adminPath: boolean = true,
) {
  const query = qs.stringify(request)
  const url = `${adminPath ? appPrefix : customerPortalPrefix}/contracts/${prettyIdentifier}/invoices?${query}`

  return apiRequest<IPaginatedQueryParams<InvoiceOrderBy>, IPaginatedResponse<IInvoice[], InvoiceOrderBy>>({
    url,
    method: 'GET',
  })
}

export function getContractNrOfPaidInvoices(prettyIdentifier: string) {
  const url = `${appPrefix}/contracts/${prettyIdentifier}/paid-invoices`

  return apiRequest<never, number>({
    url,
    method: 'GET',
  })
}

export function getContractFilterOptions() {
  const url = `${appPrefix}/contract-filters`

  return apiRequest<never, IContractFilterOptions>({
    url,
    method: 'GET',
  })
}

export function getContractStatusCount(contractObjectType: TContractObject) {
  const query = contractObjectType ? qs.stringify({ contractObjectType }) : ''
  const url = `${appPrefix}/contract-status-count?${query}`

  return apiRequest<never, IContractStatusCount[]>({
    url,
    method: 'GET',
  })
}

export function closeContract(prettyIdentifier: string) {
  const url = `${appPrefix}/contracts/${prettyIdentifier}`
  return apiRequest<never, {}>({
    url,
    method: 'DELETE',
  })
}

export function closeWarranty(prettyIdentifier: string) {
  const url = `${appPrefix}/contracts/warranty/${prettyIdentifier}`
  return apiRequest<never, { isTerminated: boolean }>({
    url,
    method: 'DELETE',
  })
}

export function archiveAllContracts(providerId: number) {
  const IS_DEBUG_FORCE_RUN = false // Default is 'false'.
  const env = CONFIG.env

  if (env !== 'demo' && !IS_DEBUG_FORCE_RUN) {
    throw Error('Running archiveAllContracts(..) is not allowed in this env: ' + env)
  } else {
    doTouchUser()
    const url = `${appPrefix}/contracts/archive-all/${providerId}`
    return apiRequest<any, any>({
      url,
      method: 'DELETE',
    })
  }
}

/**
 * @param overridingEnginePowerKW If given a number, then engine power from the vehicle lookup itself is
 *        to be overriden when requesting for (contract) templates.
 */
export function getTemplatesVehicleLookup(
  regNoOrVIN: string,
  startMileage: number = 0,
  overridingEnginePowerKW?: null | number,
) {
  doTouchUser()
  const query = encodeURI(regNoOrVIN)
  const parsedStartMileage: number = Number.parseInt('' + startMileage) || 0 // Just in case, due to detected sometimes api was getting an "object" as startMileage in some similar endpoint.

  const request: ITemplatesVehicleLookupRequest = {
    overridingEnginePowerKW: overridingEnginePowerKW,
  }

  return apiRequest<ITemplatesVehicleLookupRequest, IRegistrationNumberResponse>(
    {
      url: `${appPrefix}/templates-vehicle-lookup/${parsedStartMileage}/${query}`,
      method: 'POST',
      body: request,
    },
    true,
  )
}

/**
 * Advanced lookup for vehicle templates. Means that lookup will be done with more details and more expensive.
 * At the current moment works only for DK.
 */
export function getAdvancedTemplatesVehicleLookup(regNoOrVIN: string, startMileage: number = 0) {
  doTouchUser()
  const query = encodeURI(regNoOrVIN)
  const parsedStartMileage: number = Number.parseInt('' + startMileage) || 0 // Just in case, due to detected sometimes api was getting an "object" as startMileage in some similar endpoint.

  const request: ITemplatesVehicleLookupRequest = {
    overridingEnginePowerKW: null,
    advancedLookup: true,
    token: getRefreshToken(),
  }

  return apiRequest<ITemplatesVehicleLookupRequest, IRegistrationNumberResponse>(
    {
      url: `${appPrefix}/advanced-templates-vehicle-lookup/${parsedStartMileage}/${query}`,
      method: 'POST',
      body: request,
    },
    true,
  )
}

/**
 * @deprecated getLicenseLookup - Use new getTemplatesVehicleLookup(..) instead. 2024-04-25
 */
export function getLicenseLookup(regNoOrVIN: string, startMileage: number = 0) {
  doTouchUser()
  const query = encodeURI(regNoOrVIN)
  const parsedStartMileage: number = Number.parseInt('' + startMileage) || 0 // Just in case, due to detected sometimes api was getting an "object" as startMileage in some similar endpoint.

  return apiRequest<any, IRegistrationNumberResponse>({
    url: `${appPrefix}/registrationnumber/${parsedStartMileage}/${query}`,
    method: 'GET',
    disableErrorHandling: true,
  })
}

export function getItemSerialNumberLookup(product: Partial<ProductAlongItsContracts>) {
  doTouchUser()
  const query = encodeURI(qs.stringify(product))

  // we are only interested in any potential error messages
  return apiRequest<never, {}>({
    url: `${appPrefix}/itemSerialNumber?${query}`,
    method: 'GET',
    disableErrorHandling: true,
  })
}

export function getFuelTypes(isoCountry: string, mapToFuelType?: boolean, forProduct?: boolean) {
  const query = qs.stringify({ mapToFuelType: !!mapToFuelType, forProduct })
  const iso = encodeURI(isoCountry)

  return apiRequest<IFuelTypeRequest, FuelType[]>({
    url: `${appPrefix}/fueltypes/${iso}?${query}`,
    method: 'GET',
    disableErrorHandling: true,
  })
}

/**
 * @param startMileage Needed by V4PricingTool.
 */
export function getTemplatesFromVehicle(request: ITemplateLookupRequest, startMileage: number) {
  const query = qs.stringify(request)
  const parsedStartMileage: number = Number.parseInt('' + startMileage) || 0 // Just in case, due to detected sometimes api was getting an "object" as startMileage in some similar endpoint.

  const url = `${appPrefix}/templates/${parsedStartMileage}/lookup?${query}`

  return apiRequest<never, VehicleContracts>({
    url,
    method: 'GET',
    disableErrorHandling: true,
  })
}

export function getProductTemplates() {
  const query = qs.stringify({ request: { product: true } })
  const url = `${appPrefix}/templates?${query}`

  return apiRequest<never, VehicleContracts>({
    url,
    method: 'GET',
    disableErrorHandling: true,
  })
}

export function getContractCreationData() {
  const url = `${appPrefix}/contract-creation-data`

  return apiRequest<never, IContractCreationData>({
    url,
    method: 'GET',
  })
}

export function getContractDetailsChartData(prettyId: string) {
  const url = `${appPrefix}/stats/contracts/${prettyId}/chart`
  return apiRequest<never, IPaymentMileageCharts>({
    url,
    method: 'GET',
  })
}

export function getContractBalanceStatistics(prettyId: string) {
  const url = `${appPrefix}/contracts/${prettyId}/balance-statistics`
  return apiRequest<never, IContractBalanceStatistics>({
    url,
    method: 'GET',
  })
}

export function getContractSettlementActions(prettyId: string) {
  const url = `${appPrefix}/contracts/${prettyId}/settlement-actions`
  return apiRequest<never, ISettlementActionResponse[]>({
    url,
    method: 'GET',
  })
}

export function patchSettleAction(prettyId: string, body: ISettlementActionRequest) {
  const url = `${appPrefix}/contracts/${prettyId}/settlement-action-details`
  return apiRequest<ISettlementActionRequest, ISettlementActionResponse>({
    url,
    method: 'PATCH',
    body,
  })
}

export function settleContract(
  prettyId: string,
  sendSettlementEmail: boolean,
  directSettlement: boolean = false,
  body?: FormData,
) {
  return handleFetch(
    `${baseUrl}/admin/contracts/${prettyId}/settle/?${qs.stringify({ sendSettlementEmail, directSettlement })}`,
    {
      method: 'POST',
      body,
    },
  )
}

export function setSettledUnits(prettyId: string, body: { units: number }) {
  const url = `${appPrefix}/contracts/${prettyId}/settled-units`
  return apiRequest<{ units: number }, number>({
    url,
    method: 'PATCH',
    body,
  })
}

export function getInternalContractNotes(prettyId: string) {
  const url = `${appPrefix}/contracts/${prettyId}/internal-notes`
  return apiRequest<never, IInternalContractNoteResponse[]>({
    url,
    method: 'GET',
  })
}

export function patchInternalContractNote(prettyId: string, noteId: number, body: FormData) {
  return handleFetch(`${baseUrl}/admin/contracts/${prettyId}/internal-notes/${noteId}`, {
    method: 'PATCH',
    body,
  })
}

export function createInternalContractNote(prettyId: string, body: FormData) {
  return handleFetch(`${baseUrl}/admin/contracts/${prettyId}/internal-notes`, {
    method: 'POST',
    body,
  })
}

export function deleteInternalContractNote(prettyId: string, noteId: number) {
  const url = `${appPrefix}/contracts/${prettyId}/internal-notes/${noteId}`
  return apiRequest<never, never>({
    url,
    method: 'DELETE',
  })
}

/**
 * Get 'Overview Stats' for Provider
 * @param prettyId
 */
export function getProviderOverviewStats() {
  const url = `${appPrefix}/stats/overview/`
  return apiRequest<never, IProviderStats>({
    url,
    method: 'GET',
  })
}

export function exportSubProviderlistFileUrl(providerId: number, filetype: 'csv' | 'xls'): string {
  const token: string | null = getToken()
  if (!token) {
    throw Error('Missing token')
  }
  const mapQueryItems = new Map<string, string>([['access-token', token]])
  const queryString = createQueryString(mapQueryItems)
  return `${baseUrl}/admin/export/${providerId}/${filetype}?${queryString}`
}

export function getSubProviders(request: IPaginatedQueryParams<ContractProviderOrderBy>) {
  const query = qs.stringify(request)
  const url = `${appPrefix}/stats/provider-info/sub-providers?${query}`
  return apiRequest<
    IPaginatedQueryParams<ContractProviderOrderBy>,
    IPaginatedResponse<IContractProviderInfo[], ContractProviderOrderBy>
  >({
    url,
    method: 'GET',
  })
}

export function getMainProvider() {
  const url = `${appPrefix}/stats/provider-info/main-provider`
  return apiRequest<never, IContractProviderInfo>({
    url,
    method: 'GET',
  })
}

// Returns multiple provider data series for generating charts for the dashboard.
export function getDashboardStats(request?: IDashboardQueryParams<IDashboardFilterParams>) {
  const query = request ? qs.stringify(request) : ''
  const url = `${appPrefix}/stats/dashboard-charts?${query}`

  return apiRequest<IDashboardQueryParams<IDashboardFilterParams>, IDashboardCharts>({ url, method: 'GET' })
}

export function getDashboardContractsData(request?: IDashboardQueryParams<IAPIContractChartsFilterParams>) {
  doTouchUser()

  const query = request ? qs.stringify(request) : ''
  const url = `${appPrefix}/stats/dashboard/contracts?${query}`

  return apiRequest<
    IDashboardQueryParams<IAPIContractChartsFilterParams>,
    IDashboardContractChartsResponse<IDashboardContractChartViews, IAPIContractChartsFilterParams>
  >({
    url,
    method: 'GET',
  })
}

export function updateContractStartDate(date: string, id: string) {
  const url = `${appPrefix}/contract/start-date/${id}`
  return apiRequest<{ date: string }, never>({
    url,
    method: 'PATCH',
    body: { date },
  })
}

/********************************************************
 * LogIn API Requests
 ********************************************************/

export function login(request: ILoginRequest) {
  return apiRequest<ILoginRequest, ILoginResponse>(
    {
      url: `${appPrefix}/login`,
      method: 'POST',
      body: request,
    },
    true,
  )
}

export function loginEmailExists(request: ILoginRequest) {
  return apiRequest<ILoginRequest, ILoginResponse>(
    {
      url: `${appPrefix}/email-exists`,
      method: 'POST',
      body: request,
    },
    true,
  )
}

export function resetPassword(body: IResetPasswordRequest, resetToken: string) {
  return apiRequest<IResetPasswordRequest, never>({
    url: `${appPrefix}/reset-password`,
    method: 'POST',
    body,
    jsonResponse: false,
    extraHeaders: [{ key: 'access-token', value: resetToken }],
  })
}

export function loginForgotPassword(request: ILoginRequest) {
  return apiRequest<ILoginRequest, ILoginResponse>({
    url: `${appPrefix}/forgot-password`,
    method: 'POST',
    body: request,
    jsonResponse: false,
  })
}

export function getUserConfigurationServiceContractTable(contractObjectType?: TContractObject) {
  const type: UserConfigurationType = tableConfigProductTypeMapping[contractObjectType || 'Vehicle']
  return apiRequest<never, UserConfiguration<ContractOrderBy>>({
    url: `${appPrefix}/user-configuration/${type}`,
    method: 'GET',
  })
}

export function putUserConfigurationServiceContractTable(
  body: ITableUserConfiguration<ContractOrderBy>,
  contractObjectType?: TContractObject,
) {
  const type: UserConfigurationType = tableConfigProductTypeMapping[contractObjectType || 'Vehicle']
  return apiRequest<ITableUserConfiguration<ContractOrderBy>, any>({
    url: `${appPrefix}/user-configuration/${type}`,
    method: 'PUT',
    body,
  })
}

export function getUserConfigurationByType(type: UserConfigurationType) {
  return apiRequest<never, UserConfiguration>({
    url: `${appPrefix}/user-configuration/${type}`,
    method: 'GET',
  })
}

export function getUserConfigurations() {
  return apiRequest<never, UserConfigurations>({
    url: `${appPrefix}/user-configuration`,
    method: 'GET',
  })
}

export function getCustomers(request: IPaginatedQueryParams<CustomerListOrderByType>) {
  doTouchUser()

  const query = qs.stringify(request)
  const url = `${appPrefix}/customers?${query}`

  return apiRequest<
    IPaginatedQueryParams<CustomerListOrderByType>,
    IPaginatedResponse<IAdminCustomer[], CustomerListOrderByType>
  >({
    url,
    method: 'GET',
  })
}

export function getCustomer(customerPrettyId: string) {
  doTouchUser()

  const url = `${appPrefix}/customers/${customerPrettyId}`
  return apiRequest<never, IAdminCustomer>({
    url,
    method: 'GET',
  })
}

export function patchCustomer(customerPrettyId: string, record: Partial<IAdminCustomer>) {
  return apiRequest<Partial<IAdminCustomer>, IAdminCustomer>({
    url: `${appPrefix}/customers/${customerPrettyId}`,
    method: 'PATCH',
    body: record,
  })
}

export function getCustomerVatInfo(vat: string) {
  return apiRequest<never, IAdminCustomer>({
    url: `${appPrefix}/customer/vatlookup?vatId=${vat}`,
    method: 'GET',
    disableErrorHandling: true,
  })
}

export function getCustomerContracts(
  prettyIdentifier: string,
  request: IPaginatedQueryParams<ContractListOrderByType>,
  contractObjectType?: TContractObject,
) {
  const query = qs.stringify({ ...request, filtering: { activeTypes: [contractObjectType || 'Vehicle'] } })
  const url = `${appPrefix}/customers/${prettyIdentifier}/contracts?${query}`
  return apiRequest<
    IPaginatedQueryParams<ContractListOrderByType, IContractFilterParams>,
    IPaginatedResponse<IContractListRecord[], ContractListOrderByType>
  >({
    url,
    method: 'GET',
  })
}

export function getCustomerFromEmail(email: string) {
  const url: string = `${appPrefix}/customers?search=${email}`
  return apiRequest<never, IPaginatedResponse<IAdminCustomer[], CustomerListOrderByType>>({
    url,
    method: 'GET',
  })
}

export function lookupUser(email: string) {
  return apiRequest<never, IAdminCustomer>({
    url: `${appPrefix}/customer/email/${email}`,
    method: 'GET',
    disableErrorHandling: true,
  })
}

/********************************************************
 * User API Requests
 ********************************************************/

export function getUsers(
  request: IPaginatedQueryParams<UserListOrderByType>,
  searchQuery: string,
  providerId: number | null,
) {
  const query = qs.stringify({ ...request, searchQuery, providerId })
  const url = `admin/users?${query}`

  return apiRequest<
    IPaginatedQueryParams<UserListOrderByType>,
    IPaginatedResponse<IUserListRecord[], UserListOrderByType>
  >({
    url,
    method: 'GET',
  })
}

export function patchUser(id: number, record: IUserListRecord) {
  return apiRequest<IUserListRecord, IUserListRecord>({
    url: `${appPrefix}/users/${id}`,
    method: 'PATCH',
    body: record,
  })
}

export function getUserRoles(id: number) {
  return apiRequest<IUserListRecord, UserRoleRecord[]>({
    url: `${appPrefix}/user-roles/${id}`,
    method: 'GET',
  })
}

export async function getUserLocale(email: string) {
  const response = await getUser(email)

  if (response && response.data && response.data.localeCode) {
    return response.data.localeCode
  }
  return ''
}

export function getSupportedlocales(): Promise<IJsonStatus<{ [key in IsoLocale]: string }, ApiError>> {
  return apiRequest<never, { [key in IsoLocale]: string }>({
    url: `${appPrefix}/supportedlocales`,
    method: 'GET',
  })
}

export function patchUserLocale(locale: string) {
  doTouchUser()

  return apiRequest<any, any>({
    url: `${appPrefix}/user-locale`,
    method: 'PATCH',
    body: { localeCode: locale },
  })
}

export function deleteUser(id: number) {
  return apiRequest<IUserListRecord, IUserListRecord>({
    url: `${appPrefix}/users/${id}`,
    method: 'DELETE',
  })
}

export function createUser(record: IUserListRecord) {
  return apiRequest<IUserListRecord, IUserListRecord>({
    url: `${appPrefix}/users`,
    method: 'POST',
    body: record,
  })
}

export function getUser(email: string) {
  return apiRequest<never, IUserListRecord>({
    url: `${appPrefix}/user-by-email/${email}`,
    method: 'GET',
  })
}

export function touchUser(email: string) {
  return apiRequest<IUserListRecord, IUserListRecord>({
    url: `${appPrefix}/touch-user/${email}`,
    method: 'PATCH',
  })
}

/********************************************************
 * Provider API Requests
 ********************************************************/

export function getProviderContractTemplates(providerId: number, product?: boolean) {
  const query = qs.stringify({ product })
  const url = `${appPrefix}/contractproviders/${providerId}/contracttemplates?${query}`

  return apiRequest<any, IContractTemplateResponse[]>({
    url,
    method: 'GET',
  })
}

export function getContractProviderInfo(providerId: number) {
  const url = `${v1Prefix}/contractproviders/${providerId}/info`

  return apiRequest<any, any>({
    url,
    method: 'GET',
  })
}

export function getContractProviderDetails(providerId: number) {
  const url = `${v1Prefix}/contractproviders/${providerId}`

  return apiRequest<any, any>({
    url,
    method: 'GET',
  })
}

/********************************************************
 * Customers API Requests
 ********************************************************/

export function adminCustomerLoginRequest(prettyIdentifier: IAdminCustomerLoginRequest) {
  return apiRequest<IAdminCustomerLoginRequest, any>({
    url: `${appPrefix}/customer/sendLogin`,
    method: 'PATCH',
    body: prettyIdentifier,
    jsonResponse: false,
  })
}

/********************************************************
 * Payment API Requests
 ********************************************************/

export function calculateContractOffer(
  body:
    | IStandardContractCalculationRequest
    | ICustomContractCalculationRequest
    | IStandardAxContractCalculationRequest
    | IStandardV4PricingToolContractCalculationRequest,
) {
  return apiRequest<
    | IStandardContractCalculationRequest
    | ICustomContractCalculationRequest
    | IStandardAxContractCalculationRequest
    | IStandardV4PricingToolContractCalculationRequest,
    IContractCalculationResponse
  >({
    url: `${appPrefix}/payment/calculate-plan`,
    method: 'POST',
    body,
  })
}

export function postSettlementPayment(prettyIdentifier: string, body: ISettlementPaymentRequest) {
  return apiRequest<ISettlementPaymentRequest, {}>({
    url: `${appPrefix}/payment/${prettyIdentifier}/settlement-payment`,
    method: 'POST',
    body,
  })
}

export function getStripeSettlementPaymentProcessingStatus(prettyIdentifier: string) {
  return apiRequest<never, boolean>({
    url: `${appPrefix}/payment/${prettyIdentifier}/payment-processing-status`,
    method: 'GET',
  })
}

export function postContractOffer(
  body: ICustomContractCreationRequest | IStandardContractCreationRequest,
  isBuyNow: boolean = false,
) {
  // @NOTE - We need to butcher the vehicle regDate for the service to accept it from
  // 2018-09-24T12:15:07.759Z -> 2018-09-24
  let requestBody = { ...body }
  if (requestBody && requestBody.product && requestBody.product.regDate) {
    const date = requestBody.product.regDate.substring(0, requestBody.product.regDate.indexOf('T'))
    requestBody = { ...requestBody, product: { ...requestBody.product, regDate: date } }
  }

  const url = `${appPrefix}/offers/create${isBuyNow ? '?buyNow=1' : ''}`
  return apiRequest<ICustomContractCreationRequest | IStandardContractCreationRequest, IContractCreationResponse>(
    {
      url,
      method: 'POST',
      body: requestBody,
    },
    true,
  )
}

export function postCustomTerms(prettyIdentifier: string, body: IAdminCustomTermsRecord) {
  return apiRequest<IAdminCustomTermsRecord, never>({
    url: `${appPrefix}/contracts/${prettyIdentifier}/custom-terms`,
    method: 'PATCH',
    body,
    jsonResponse: false,
  })
}

export function postContractAdjust(
  body: IContractAdjustmentRequest,
  prettyIdentifier: string,
  isBuyNow: boolean = false,
) {
  return apiRequest<IContractAdjustmentRequest, IAdminContractActivationResponse>({
    url: `${appPrefix}/contracts/${prettyIdentifier}/adjust${isBuyNow ? '?buyNow=1' : ''}`,
    method: 'PUT',
    body,
  })
}

export function postContractPrint(body: IContractPrintCreationRequest) {
  // @NOTE - We need to butcher the vehicle regDate for the service to accept it from
  // 2018-09-24T12:15:07.759Z -> 2018-09-24
  let requestBody = { ...body }
  if (requestBody && requestBody.product && requestBody.product.regDate) {
    const date = requestBody.product.regDate.substring(0, requestBody.product.regDate.indexOf('T'))
    requestBody = { ...requestBody, product: { ...requestBody.product, regDate: date } }
  }

  return apiRequest<IContractPrintCreationRequest, IContractOfferPrintData>({
    url: `${appPrefix}/offers/print`,
    method: 'POST',
    body: requestBody,
  })
}

export const termsPdfUrl = (url: string) => `${url}?access-token=${getToken() || ''}`
export const noteAttachmentUrl = (url: string) => `${url}?access-token=${getToken() || ''}`

export const contractPdfUrl = (prettyIdentifier: string) =>
  `${baseUrl}/${appPrefix}/contracts/${prettyIdentifier}/pdf?access-token=${getToken() || ''}`

export const contractPdfUrlForReportLink = (prettyIdentifier: string) =>
  `${baseUrl}/${appPrefix}/reports/contracts/${prettyIdentifier}/pdf?access-token=${getToken() || ''}`

export const invoiceDownloadUrl = (url: string) => `${url}?access-token=${getToken() || ''}`

export const pdfDownloadUrl = (token: string) => `${baseUrl}/${appPrefix}/offers/${token}/print`

export const getPdfAgreementUrl = (prettyIdentifier: string) =>
  `${baseUrl}/${appPrefix}/contracts/${prettyIdentifier}/pdf?access-token=${getToken() || ''}`

export const getPdfAgreementUrlReportLink = (id: string) =>
  `${baseUrl}/${appPrefix}/reports/contracts/${id}/pdf?access-token=${getToken() || ''}`

export function resendOffer(prettyIdentifier: string, offerExpiresAt: Date) {
  return apiRequest<{ offerExpiresAt: string }, {}>({
    url: `${appPrefix}/contracts/${prettyIdentifier}/resend-offer`,
    method: 'POST',
    body: {
      offerExpiresAt: offerExpiresAt.toISOString(), // Convert Date to ISO string
    },
  })
}

export function getContractAdjustment(prettyIdentifier: string) {
  const url: string = `${appPrefix}/contracts/${prettyIdentifier}/adjustment-data`
  return apiRequest<never, IAdminContractAdjustmentResponse>({
    url,
    method: 'GET',
  })
}

export function getContractOfferCopy(prettyIdentifier: string) {
  const url: string = `${appPrefix}/contracts/${prettyIdentifier}/offer-data`
  return apiRequest<never, IAdminContractOfferCopyResponse>({
    url,
    method: 'GET',
  })
}

export const exportUrl = (options: {
  type: 'contracts' | 'invoices'
  format: 'csv' | 'xls'
  dateFrom?: Date
  dateTo?: Date
}) => {
  // build query: access-token / dateFrom / dateTo
  const query = qs.stringify({
    'access-token': getToken(),
    fromdate: options.dateFrom && formatDate(options.dateFrom, { rawFormat: 'yyyy-MM-dd' }),
    todate: options.dateTo && formatDate(options.dateTo, { rawFormat: 'yyyy-MM-dd' }),
  })
  return `${baseUrl}/${appPrefix}/export/${options.type}/${options.format}?${query}`
}

/********************************************************
 * Stripe API Requests
 ********************************************************/

export function getTOS() {
  return apiRequest<any, IStripeTOSAcceptance>(
    {
      url: `${appPrefix}/stripe/account-acceptance`,
      method: 'GET',
    },
    true,
  )
}

export function updateTOS() {
  return apiRequest<any, IStripeTOSAcceptance>({
    url: `${appPrefix}/stripe/account-acceptance`,
    method: 'POST',
  })
}

export function sendAddCardRequest(
  body: IPreparePaymentRequest,
): Promise<IJsonStatus<IPreparePaymentResponse, ApiError>> {
  return apiRequest<IPreparePaymentRequest, IPreparePaymentResponse>({
    url: `${customerPortalPrefix}/constracts/prepare-card-setup`,
    method: 'POST',
    body,
  })
}

/********************************************************
 * Customer Portal API Requests
 ********************************************************/

export function getCustomerProvidersAndVehicles() {
  return apiRequest<never, IPaginatedResponse<IProviderWithContracts[], never>>({
    url: `${customerPortalPrefix}/providers-and-vehicles`,
    method: 'GET',
  })
}

export function getCustomerProducts() {
  return apiRequest<never, IPaginatedResponse<IApiOtherWithContracts[], never>>({
    url: `${customerPortalPrefix}/products`,
    method: 'GET',
  })
}

export function getCustomerProvidersAndProducts() {
  return apiRequest<never, IPaginatedResponse<IProviderWithContracts[], never>>({
    url: `${customerPortalPrefix}/providers-and-products`,
    method: 'GET',
  })
}

export function getContractCreditCards(prettyIdentifier: string) {
  return apiRequest<never, ICreditCardRecord[]>({
    url: `${customerPortalPrefix}/contracts/${prettyIdentifier}/cards`,
    method: 'GET',
  })
}

export function deleteCreditCard(prettyIdentifier: string, cardId: string) {
  return apiRequest<never, never>({
    url: `${customerPortalPrefix}/contracts/${prettyIdentifier}/cards/${cardId}`,
    method: 'DELETE',
  })
}

export function makeCreditCardDefault(prettyIdentifier: string, cardId: string) {
  const body = { cardId }
  return apiRequest<any, never>({
    url: `${customerPortalPrefix}/contracts/${prettyIdentifier}/default-card`,
    method: 'PATCH',
    body,
  })
}

export function addCreditCard(body: ICreditCard, prettyIdentifier: string) {
  return apiRequest<ICreditCard, ICreditCardRecord>({
    url: `${customerPortalPrefix}/contracts/${prettyIdentifier}/cards`,
    method: 'POST',
    body,
  })
}

// todo: remove
export function updateCustomer(body: IAdminCustomer, prettyIdentifier: string) {
  return apiRequest<IAdminCustomer, IAdminCustomer>({
    url: `${customerPortalPrefix}/customers/${prettyIdentifier}`,
    method: 'PATCH',
    body,
  })
}

export function updateUser(body: IAdminUserInfo) {
  return apiRequest<IAdminUserInfo, IAdminUserInfo>({
    url: `${appPrefix}/users`,
    method: 'PATCH',
    body,
  })
}

export function updatePassword(body: IUserPasswordChangeRequest) {
  return apiRequest<IUserPasswordChangeRequest, any>({
    url: `${appPrefix}/change-password`,
    method: 'PATCH',
    body,
  })
}

export function updateCustomerPassword(body: ICustomerPasswordChangeRequest, prettyIdentifier: string) {
  return apiRequest<ICustomerPasswordChangeRequest, any>({
    url: `${appPrefix}/change-customer-password/${prettyIdentifier}`,
    method: 'PATCH',
    body,
  })
}

export function updateRole(body: IUserRoleChangeRequest) {
  return apiRequest<IUserRoleChangeRequest, any>({
    url: `${appPrefix}/${CHANGE_ROLE_PATH}`,
    method: 'PATCH',
    body,
  })
}

export function verifyPassword(body: { password: string }) {
  return apiRequest<{ password: string }, any>({
    url: `${appPrefix}/verify-password`,
    method: 'POST',
    body,
  })
}

/********************************************************
 * Stripe payment set up  API Requests
 ********************************************************/

export function getPublicKey() {
  return apiRequest<never, IPublicKeyResponse>({
    url: `${appPrefix}/contracts/get-public-key`,
    method: 'GET',
  })
}

export function getPaymentInformation(prettyIdentifier: string) {
  return apiRequest<never, IPaymentInformationResponse>({
    url: `${appPrefix}/contracts/${prettyIdentifier}/payment-information`,
    method: 'GET',
  })
}

export function createSetupIntent(prettyIdentifier: string) {
  return apiRequest<never, ISetupIntentResponse>({
    url: `${appPrefix}/contracts/${prettyIdentifier}/setup-intent-key`,
    method: 'GET',
  })
}

export function setPaymentMethod(prettyIdentifier: string, body: ISetPaymentMethodRequest) {
  return apiRequest<ISetPaymentMethodRequest, ISetPaymentMethodResponse>({
    url: `${appPrefix}/contracts/${prettyIdentifier}/set-payment-method`,
    method: 'POST',
    body,
  })
}

export function registerFailedBuyNowAttempt(prettyIdentifier: string) {
  return apiRequest<never, never>({
    url: `${appPrefix}/contracts/${prettyIdentifier}/register-failed-buy-now`,
    method: 'POST',
  })
}
/********************************************************
 * Other API Requests
 ********************************************************/

export function getLocaleRecords() {
  return apiRequest<any, any>({ url: `${appPrefix}/locale-records`, method: 'GET' })
}

// TODO: mofify after customer portal removed
export function postInvoicePayment(invoiceId: number, adminPath: boolean = true) {
  const url = `${adminPath ? appPrefix : customerPortalPrefix}/invoices/${invoiceId}/pay`

  return apiRequest<never, ISetPaymentMethodResponse>({
    url,
    method: 'POST',
  })
}
// TODO: mofify after customer portal removed
export const getRetryAttemptsCount = (invoiceId: number, adminPath: boolean = true) =>
  apiRequest<never, number>({
    url: `${adminPath ? appPrefix : customerPortalPrefix}/invoices/${invoiceId}/retry-attempts-count`,
    method: 'GET',
  })

/********************************************************
 * Report Requests
 ********************************************************/

export function isOldContractsDownloadEnabled(): Promise<IJsonStatus<boolean, ApiError>> {
  return apiRequest<any, boolean>({
    url: `${reportsPrefix}/isoldcontractsdownloadenabled`,
    method: 'GET',
  })
}

export function isSigmaPayoutMatchEnabled(): Promise<IJsonStatus<boolean, ApiError>> {
  return apiRequest<any, boolean>({ url: `${reportsPrefix}/issigmapayoutmatchenabled`, method: 'GET' })
}

export async function getSigmaSql(): Promise<IJsonStatus<string, ApiError>> {
  return apiRequest<any, string>({ url: `${reportsPrefix}/sigmapayoutmatches`, method: 'GET' })
}

// Using 'raw' fetch because tfetch has problems with file upload atm...
export function uploadPayoutPaymentConnections(body: FormData) {
  return handleFetch(`${baseUrl}/${reportsPrefix}/uploadpayoutpayments`, {
    method: 'POST',
    body,
  })
}

export function getReportMap(): Promise<IJsonStatus<IReportDefinitionsResponse, ApiError>> {
  return apiRequest<never, IReportDefinitionsResponse>({ url: `${reportsPrefix}/reportmap`, method: 'GET' })
}

export function getReportDefinition(
  reportName: string,
  contractObjectType?: TContractObject,
): Promise<IJsonStatus<IReportDefinition, ApiError>> {
  const params = encodeURI(reportName)
  const query = qs.stringify({ contractObjectType: contractObjectType })
  return apiRequest<never, IReportDefinition>({
    url: `${reportsPrefix}/reportdefinition/${params}?${query}`,
    method: 'GET',
    disableErrorHandling: true,
  })
}

export function getReportResult(req: IReportSearchRequest): Promise<IJsonStatus<IReportSearchResponse, ApiError>> {
  doTouchUser()

  return apiRequest<IReportSearchRequest, IReportSearchResponse>({
    url: `${reportsPrefix}/getreport`,
    method: 'POST',
    body: req,
  })
}

export function reportExportUrl(options: { path?: string; format: 'csv' | 'xls'; req: IReportSearchRequest }): string {
  const req: string = JSON.stringify(options.req)
  const token: string | null = getToken()
  if (!token) {
    throw Error('Missing token')
  }
  const mapQueryItems = new Map<string, string>([
    ['access-token', token],
    ['req', req],
  ])
  const queryString = createQueryString(mapQueryItems)
  return `${baseUrl}/${reportsPrefix}/export/report/${options.format}?${queryString}`
}

function createQueryString(mapItems: Map<string, string>): string {
  const items: string[] = []
  mapItems.forEach((value, key) => {
    const encodedKey = encodeURIComponent(key)
    const strWorkarounded = value.replace(/(%)/g, '*')
    const encodedValue = encodeURIComponent(strWorkarounded)
    const item = `${encodedKey}=${encodedValue}`
    items.push(item)
  })
  return items.join('&')
}

export function getContractDetailsFromReportLink(prettyIdentifier: string) {
  return apiRequest<never, IContractDetailsRecord>({
    url: `${appPrefix}/reports/contracts/${prettyIdentifier}/details`,
    method: 'GET',
  })
}

export function getContractBasicInfoFromReportLink(prettyIdentifier: string) {
  return apiRequest<never, IBasicContractInfo>({
    url: `${appPrefix}/reports/contracts/${prettyIdentifier}/basic-info`,
    method: 'GET',
  })
}

export function getContractChartFromReportLink(prettyIdentifier: string) {
  return apiRequest<never, IPaymentMileageCharts>({
    url: `${appPrefix}/stats/reports/contracts/${prettyIdentifier}/chart`,
    method: 'GET',
  })
}

/********************************************************
 * Release Notes API Requests
 ********************************************************/

export function getReleaseVersions(offset: number) {
  doTouchUser()

  return apiRequest<never, IReleaseNotesResponse[]>({
    url: `admin/release/versions?offset=${offset}`,
    method: 'GET',
  })
}

export function getLastReleaseVersion() {
  return apiRequest<never, IReleaseVersionResponse>({
    url: 'admin/release/last-version',
    method: 'GET',
  })
}

export function getNumberOfContractsCloseToExpiration(contractObjectType: TContractObject) {
  const query = qs.stringify({ contractObjectType })
  const url = `${appPrefix}/count-expiring-contracts?${query}`

  return apiRequest<never, number>({
    url,
    method: 'GET',
  })
}

export function getNumberOfContractsNeedSettlement(contractObjectType: TContractObject) {
  const query = qs.stringify({ contractObjectType })
  const url = `${appPrefix}/count-contracts-for-settlement?${query}`

  return apiRequest<never, number>({
    url,
    method: 'GET',
  })
}

export function getPaymentFailureReason(prettyId: string, actionId?: number) {
  const url = `${appPrefix}/suspension-reason/${prettyId}/${actionId || '-1'}`

  return apiRequest<never, IPaymentFailureReason | {}>({
    url,
    method: 'GET',
  })
}

export function getPaymentFailureReasonForCustomer(prettyId: string) {
  const url = `${appPrefix}/customer-suspension-reason/${prettyId}`

  return apiRequest<never, string>({
    url,
    method: 'GET',
  })
}

export function getSettlementPaymentFailureReason(prettyId: string) {
  const url = `${appPrefix}/failed-settlement-payment-reason/${prettyId}`

  return apiRequest<never, { reason?: IPaymentFailureReason; nextAttempt?: string }>({
    url,
    method: 'GET',
  })
}

/********************************************************
 * Vehicle Blacklist API Requests
 ********************************************************/
export function getVehicleBlacklist(
  request: IPaginatedQueryParams<BlacklistedVehicleOrderByType>,
  searchQuery: string,
  providerId: number,
) {
  request.search = searchQuery
  const query = qs.stringify(request)
  const url = `${appPrefix}/vehicle-blacklist/${providerId}?${query}`
  return apiRequest<
    IPaginatedQueryParams<BlacklistedVehicleOrderByType>,
    IPaginatedResponse<IBlacklistedVehicle[], BlacklistedVehicleOrderByType>
  >({
    url,
    method: 'GET',
  })
}

export function createVehicleBlacklist(body: IBlacklistedVehicle, providerId: number) {
  const url = `${appPrefix}/vehicle-blacklist/${providerId}`
  return apiRequest<IBlacklistedVehicle, IBlacklistedVehicle>({
    url,
    method: 'POST',
    body,
  })
}

export function deleteVehicleBlacklist(ids: number[], providerId: number) {
  const url = `${appPrefix}/vehicle-blacklist/${providerId}`
  return apiRequest<number[], IBlacklistedVehicle>({
    url,
    method: 'DELETE',
    body: ids,
  })
}

export function updateVehicleBlacklist(id: number, body: IBlacklistedVehicle, providerId: number) {
  const url = `${appPrefix}/vehicle-blacklist/${providerId}/${id}`
  return apiRequest<IBlacklistedVehicle, IBlacklistedVehicle>({
    url,
    method: 'PUT',
    body,
  })
}

// @TODO: using 'raw ish' fetch because tfetch has problems with file upload atm...
export function uploadVehicleBlacklistFile(filetype: string, body: FormData, providerId: number) {
  return handleFetch(`${baseUrl}/admin/vehicle-blacklist/${providerId}/${filetype}`, {
    method: 'POST',
    body,
  })
}

export function exportBlacklistFileUrl(providerId: number, filetype: 'csv' | 'xls'): string {
  const token: string | null = getToken()
  if (!token) {
    throw Error('Missing token')
  }
  const mapQueryItems = new Map<string, string>([['access-token', token]])
  const queryString = createQueryString(mapQueryItems)
  return `${baseUrl}/admin/vehicle-blacklist/${providerId}/${filetype}?${queryString}`
}

export function lookupVehicleBlacklist(body: {
  brand: string
  model: string | undefined
  fuelType: string | undefined
}) {
  const query = qs.stringify(body)

  return apiRequest<never, boolean>({
    url: `${appPrefix}/vehicle-blacklist/contract/vehicle-lookup?${query}`,
    method: 'GET',
  })
}

export function getAllowedProvidersForBlacklists() {
  return apiRequest<never, RoleProvider[]>({
    url: `${appPrefix}/vehicle-blacklist/providers/allowed-providers`,
    method: 'GET',
  })
}

/********************************************************
 * Fragus support/contact information API Requests
 ********************************************************/

export function getContactInformation(isoLocale: string) {
  return apiRequest<IContactInformation, IContactInformation>({
    url: `${appPrefix}/support/contact-information/${isoLocale}`,
    method: 'GET',
  })
}

/********************************************************
 * Contract PendingTermination API Requests
 ********************************************************/
export function createPendingTermination(id: string, body: { date: string }) {
  return apiRequest<{ date: string }, never>({
    url: `${appPrefix}/contracts/${id}/create-pending-termination`,
    method: 'POST',
    body,
  })
}

export function cancelPendingTermination(id: string) {
  return apiRequest<{ date: string }, never>({
    url: `${appPrefix}/contracts/${id}/cancel-pending-termination`,
    method: 'POST',
  })
}

export function getPendingTerminationDate(id: string) {
  return apiRequest<never, string>({
    url: `${appPrefix}/contracts/pending-termination-date/${id}`,
    method: 'GET',
  })
}

export function getMinimumTerminationDate(id: string) {
  return apiRequest<never, string>({
    url: `${appPrefix}/contracts/minimum-termination-date/${id}`,
    method: 'GET',
  })
}

export function getStatusOfMinimumPaymentsCount(id: string) {
  return apiRequest<never, boolean>({
    url: `${appPrefix}/contracts/minimum-payments/${id}`,
    method: 'GET',
  })
}
/**
 * refreshes access-token and refresh-token
 * @param token
 */
export const refreshTokens = (token: string) =>
  apiRequest<{ token: string | undefined }, ILoginResponse>(
    {
      url: `${appPrefix}/refresh-token`,
      method: 'POST',
      body: { token },
    },
    true,
    true,
  )
/**
 * logs out user (kills session)
 * NOTE: use before refresh-token cleanup
 */
export const logout = () =>
  apiRequest<{ token: string | undefined }, ILoginResponse>({
    url: `${appPrefix}/logout`,
    method: 'POST',
    body: { token: getRefreshToken() },
  })

/**
 * Creates a link to download a xls file with filtered shallow contracts
 * @param request
 */
export function exportShallowContracts(request: IPaginatedQueryParams<ContractOrderBy, IContractFilterParams>): string {
  const query = qs.stringify({ ...request, 'access-token': getToken() })
  return `${baseUrl}/admin/export/contracts-list/xls/?${query}`
}

export function checkIsSuspendedExpired(prettyIdentifier: string) {
  const url: string = `${appPrefix}/contracts/${prettyIdentifier}/check-suspended-expired`
  return apiRequest<never, boolean>({
    url,
    method: 'GET',
  })
}

export function getAdvancedLookupAbility(vin: string) {
  return apiRequest<{ token: string | undefined }, boolean>({
    url: `${appPrefix}/check-advanced-lookup-ability/${encodeURI(vin)}`,
    method: 'POST',
    body: {
      token: getRefreshToken(),
    },
  })
}

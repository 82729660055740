import { CardContent, CircularProgress } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { FilterNone as FilterNoneIcon } from '@material-ui/icons'
import { formatCurrency, formatDate, formatMileage } from '@omnicar/sam-format'
import { IContractDetailsRecord } from '@omnicar/sam-types'
import {
  contractPdfUrl,
  contractPdfUrlForReportLink,
  getPdfAgreementUrl,
  getPdfAgreementUrlReportLink,
  termsPdfUrl,
  updateContractStartDate,
} from 'api/api'
import React from 'react'
import { t } from 'translations/translationFunctions'
import './index.css'
import { ContractState, PaymentGateway } from '@omnicar/sam-types'
import classNames from 'classnames'
import ContractStartDateInput from 'components/admin/Contract/Details/Specifics/ContractStartDateInput'
import { withContractDisplayConfig } from 'components/ContractDisplayConfig/withContractDisplayConfig'
import IncludedOptionsList from 'components/IncludedOptionsList'
import { Card } from 'components/Mui/Card'
import { PdfLinkButton } from 'components/Mui/LinkButton'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import Typography from 'components/Typography'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { contractDetailsPath } from 'routes/paths'
import { AppContext } from 'store/appContext'
import { createPanelStyles } from 'theme'
import { IValueTypeTranslations, valueTypeTranslations } from 'translations/ValueTypeTranslations'
import { parseDate } from 'utils/date'
import { IContractDisplayConfig } from 'reducers/contractDisplayConfig/initialState'

interface IOwnProps {
  id: string
  onClick?: () => void
  loading: boolean
  record?: IContractDetailsRecord
  initContractSpecifics: () => void
  fromReportLink?: boolean
  contractState: ContractState
  contractStateName: string
}

type TProps = IOwnProps & WithStyles<typeof styles> & IContractDisplayConfig

interface IState {
  editStartDate: boolean
  editableStartDate: string
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    additionalsList: {
      marginBottom: theme.spacing(3),
    },
    downloadBtnLabel: {
      justifyContent: 'flex-start',
    },
    templateIdValue: { whiteSpace: 'normal', fontSize: '95%' }, // Note: The internal name tends to be long, allow the line to break into two lines, so the far-right doesn't get hidded (so that the Pdf links doesn't disapear)
    sharperText: {
      fontWeight: 415,
      color: '#111',
    },
    extrainfo: {
      marginLeft: '-6px',
      marginRight: '-6px',
      marginTop: '-6px',
      marginBottom: '5px',
      padding: '6px',
    },
  })

class ContractDetailsSpecifics extends React.Component<TProps, IState> {
  public pdfAgreementUrl: string = ''

  constructor(props: TProps) {
    super(props)

    this.state = {
      editStartDate: false,
      editableStartDate: this.getStartDateFromRecord(props),
    }
  }

  public componentDidMount() {
    this.props.initContractSpecifics()
    this.initData()
  }

  public componentDidUpdate(prevProps: TProps) {
    if (this.props.id !== prevProps.id) {
      this.props.initContractSpecifics()
      this.initData()
    }

    if (prevProps.record?.startDate !== this.props.record?.startDate) {
      this.setState({ editableStartDate: this.getStartDateFromRecord() })
    }
  }

  public render() {
    const {
      classes,
      id,
      record,
      loading,
      fromReportLink,
      contractState,
      contractStateName,
      hiddenVAT,
      showMileageInfo,
    } = this.props
    const showAdditionals = record && record.contractType !== 'EXTERNAL' && this.contractHasAdditionalOptions(record)
    const showIncluded = record && record.contractType !== 'EXTERNAL' && this.contractHasIncludedOptions(record)
    const { editStartDate, editableStartDate } = this.state
    const allowStartDateEdit =
      record &&
      (record.contractType === 'EXTERNAL' || record?.contractProductType === 'Warranty') &&
      contractState === ContractState.ActivePrePaid
    const isProductContract = !!(record && record.isProduct)

    let typeTranslations = record && (record.valueType ? valueTypeTranslations[record.valueType] : undefined)
    let startTypeTranslations =
      record && (record.startValueType ? valueTypeTranslations[record.startValueType] : undefined)

    let durationWithValues = record && `${record.duration} ${t('months')}`
    if (showMileageInfo) {
      durationWithValues += this.getDurationWithValues(typeTranslations, isProductContract)
    }
    const htmlFilesContainer: JSX.Element[] = this.renderfilesContainer(record?.filesContainerAlongContract)

    let translatedContractProductType: string = '-'
    switch (record?.contractProductType) {
      case 'ServiceAgreement':
        translatedContractProductType = t('Service Agreement')
        break
      case 'Warranty':
        translatedContractProductType = t('Warranty Contract')
        break
      case 'WarrantyAndServiceAgreement':
        translatedContractProductType = t('Warranty / Service Agreement')
        break
      default:
        translatedContractProductType = '-'
    }

    return (
      <AppContext.Consumer>
        {({ isSuperAdmin, providerInfo }) => (
          <Panel>
            <PanelHeader>
              <PanelTitle>{t('Contract details')}</PanelTitle>
            </PanelHeader>
            <PanelContent>
              <Card>
                <CardContent>
                  <div className={classes.cardContentTitleRow}>
                    <Typography className={classes.cardTitle} variant="title">
                      <FilterNoneIcon className={classes.cardTitleIcon} />
                      <span className={classes.cardTitleText}>{record?.contractName}</span>
                    </Typography>
                    {loading && <CircularProgress size={32} color="secondary" />}
                  </div>
                  <div className="ContractDetailsSpecifics__main-info">
                    <div className="ContractDetailsSpecifics__info">
                      <div className="ContractDetailsSpecifics__info-titles container">
                        <div className="ContractDetailsSpecifics__info-titles-item">{t('Agreement')} #:</div>
                        <div className="ContractDetailsSpecifics__info-titles-item">{t('Contract Product Type')}:</div>
                        <div className="ContractDetailsSpecifics__info-titles-item">
                          {isProductContract ? t('Duration') : t('Duration / Distance')}:
                        </div>
                        <div className="ContractDetailsSpecifics__info-titles-item">{t('Start Date')}:</div>
                        <div className="ContractDetailsSpecifics__info-titles-item">{t('End Date')}:</div>
                        <div className="ContractDetailsSpecifics__info-titles-item">{t('Payment Method')}:</div>
                        <div className="ContractDetailsSpecifics__info-titles-item">
                          {t('Minimum Number Of Payments')}:
                        </div>
                      </div>
                      <div className="ContractDetailsSpecifics__info-values">
                        <div className="ContractDetailsSpecifics__info-values-item">
                          {record && record.prettyIdentifierShort}
                        </div>
                        <div>{translatedContractProductType}</div>

                        <div className="ContractDetailsSpecifics__info-values-item">
                          {record ? durationWithValues : ''}
                        </div>
                        <div className="ContractDetailsSpecifics__info-values-item ContractDetailsSpecifics__editable">
                          {!editStartDate && <span>{this.getStartDateFromRecord()}</span>}
                          {isSuperAdmin && allowStartDateEdit && (
                            <ContractStartDateInput
                              onCancel={this.handleStartDateEditCancel}
                              onEdit={this.handleStartDateEdit}
                              onSubmit={this.handleStartDateSubmit(providerInfo?.country || 'DK')}
                              isEditing={editStartDate}
                              value={editableStartDate}
                              onChange={this.handleStartDateChange}
                            />
                          )}
                        </div>
                        <div className="ContractDetailsSpecifics__info-values-item">
                          {record ? formatDate(record.expirationDate) : ''}
                        </div>
                        <div className="ContractDetailsSpecifics__info-values-item">
                          {record ? this.mapPaymentGateways(record.paymentMethod) : ''}
                        </div>
                        <div className="ContractDetailsSpecifics__info-values-item">
                          {record ? record.contractTemplateMinPaymentsCount || 0 : ''}
                        </div>
                      </div>
                    </div>
                    <div className="ContractDetailsSpecifics__info">
                      <div className="ContractDetailsSpecifics__info-titles container">
                        <div className="ContractDetailsSpecifics__info-titles-item">{t('Contract Type')}:</div>
                        <div className="ContractDetailsSpecifics__info-titles-item">
                          {t(hiddenVAT ? 'Total price' : 'Total price incl. VAT')}:
                        </div>
                        <div className="ContractDetailsSpecifics__info-titles-item">
                          {t(hiddenVAT ? 'Customer price/mth.' : 'Customer price/mth. incl. VAT')}:
                        </div>
                        <div className="ContractDetailsSpecifics__info-titles-item">
                          {t(hiddenVAT ? 'Dealer share' : 'Dealer share incl. VAT')}:
                        </div>
                        <div className="ContractDetailsSpecifics__info-titles-item">
                          {record &&
                            providerInfo &&
                            ((!providerInfo.parentProviderId && record.createdByProvider !== record.provider) ||
                              (providerInfo.parentProviderId &&
                                record.createdByProvider !== providerInfo.administrativeName)) &&
                            t('Created by')}
                        </div>
                        {record && record.seller && (
                          <div className="ContractDetailsSpecifics__info-titles-item">{t('Seller')}:</div>
                        )}
                        {isProductContract ? (
                          <>
                            {record && record.startValueType !== 'Services' && startTypeTranslations && (
                              <div className="ContractDetailsSpecifics__info-titles-item">
                                {t(startTypeTranslations.startValue)}:
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {showMileageInfo && (
                              <>
                                <div className="ContractDetailsSpecifics__info-titles-item">
                                  {t('Odometer at expiration')}:
                                </div>
                                <div className="ContractDetailsSpecifics__info-titles-item">{t('Start Mileage')}:</div>
                              </>
                            )}
                          </>
                        )}
                        {record && record.extendedFrom && (
                          <div className="ContractDetailsSpecifics__info-titles-item">{t('Extended from')}:</div>
                        )}
                        {record && record.adjustedTo && (
                          <div className="ContractDetailsSpecifics__info-titles-item">{t('Extended to')}:</div>
                        )}
                        {record?.contractType === 'EXTERNAL' && record?.warrantyReference && (
                          <div className="ContractDetailsSpecifics__info-titles-item">{t('Reference')}:</div>
                        )}
                      </div>
                      <div className="ContractDetailsSpecifics__info-values">
                        <div title={t('The internal type of this contract')}>
                          {record ? record.contractType.toLocaleUpperCase() : ''}
                        </div>
                        <div className="ContractDetailsSpecifics__info-values-item">
                          {record
                            ? formatCurrency(record.totalPrice.priceInclVat, { symbolDisplayType: 'APPEND' })
                            : ''}
                        </div>
                        <div className="ContractDetailsSpecifics__info-values-item">
                          {record
                            ? formatCurrency(record.monthlyPrice.priceInclVat, { symbolDisplayType: 'APPEND' })
                            : ''}
                        </div>
                        <div className="ContractDetailsSpecifics__info-values-item">
                          {record
                            ? formatCurrency(record.providerShare.priceInclVat, { symbolDisplayType: 'APPEND' })
                            : ''}
                        </div>
                        <div className="ContractDetailsSpecifics__info-values-item">
                          {record &&
                            providerInfo &&
                            ((!providerInfo.parentProviderId && record.createdByProvider !== record.provider) ||
                              (providerInfo.parentProviderId &&
                                record.createdByProvider !== providerInfo.administrativeName)) &&
                            record.createdByProvider}
                        </div>
                        {record && record.seller && (
                          <div className="ContractDetailsSpecifics__info-values-item">
                            {record.seller.name !== '' ? record.seller.name : record.seller.email}
                          </div>
                        )}
                        {isProductContract ? (
                          <>
                            {record && record.startValueType !== 'Services' && startTypeTranslations && (
                              <div className="ContractDetailsSpecifics__info-values-item">
                                {record ? `${record.startValue} ${t(startTypeTranslations.shortValue)}` : ''}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {showMileageInfo && (
                              <>
                                <div className="ContractDetailsSpecifics__info-values-item">
                                  {record?.contractProductType === 'Warranty'
                                    ? formatMileage(record.maxEndMileage ? record.maxEndMileage : record.mileage, {
                                        symbolDisplayType: 'APPEND',
                                      })
                                    : record
                                    ? formatMileage(record.endMileage, { symbolDisplayType: 'APPEND' })
                                    : ''}
                                </div>
                                <div className="ContractDetailsSpecifics__info-values-item">
                                  {record
                                    ? formatMileage(record.startMileage || 0, { symbolDisplayType: 'APPEND' })
                                    : ''}
                                </div>
                              </>
                            )}
                          </>
                        )}
                        {record && record.extendedFrom && (
                          <div className="ContractDetailsSpecifics__info-values-item">
                            <Link to={contractDetailsPath(record.extendedFrom.prettyIdentifier)}>
                              {record.extendedFrom.prettyIdentifier}
                            </Link>
                          </div>
                        )}
                        {record && record.adjustedTo && (
                          <div className="ContractDetailsSpecifics__info-values-item">
                            <Link to={contractDetailsPath(record.adjustedTo)}>{record.adjustedTo}</Link>
                          </div>
                        )}
                        {record?.contractType === 'EXTERNAL' && record?.warrantyReference && (
                          <div className="ContractDetailsSpecifics__info-values-item">{record?.warrantyReference}</div>
                        )}
                      </div>
                    </div>
                    <div className="ContractDetailsSpecifics__downloads">
                      {record && record.contractType !== 'EXTERNAL' && record.contractPdfUrl && (
                        <PdfLinkButton
                          caption={t('Agreement')}
                          href={fromReportLink ? contractPdfUrlForReportLink(id) : contractPdfUrl(id)}
                        />
                      )}

                      {record && record.termsPdfUrl && (
                        <PdfLinkButton caption={t('Terms')} href={termsPdfUrl(record.termsPdfUrl)} />
                      )}
                      {/* If no one misses the button/link we can rewmove it after 2025-03 */}
                      {/* {record && record.termsOfTradePdfUrl && (
                        <PdfLinkButton caption={t('Terms of Trade')} href={termsPdfUrl(record.termsOfTradePdfUrl)} />
                      )} */}
                    </div>
                  </div>

                  {/* --- Distinctive background indicating that this can ONLY be viewed by SuperAdmins --- */}
                  {isSuperAdmin && record && (
                    <div className={classNames(classes.extrainfo, 'bg-for-only-superadmins')}>
                      <div className="ContractDetailsSpecifics__main-info">
                        <div className="ContractDetailsSpecifics__info">
                          <div className={classNames('ContractDetailsSpecifics__info-titles', classes.sharperText)}>
                            {record.stripeCustomerLink && (
                              <div className="ContractDetailsSpecifics__info-titles-item">{t('Stripe customer')}:</div>
                            )}
                            {record.stripeSubscriptionLink && (
                              <div className="ContractDetailsSpecifics__info-titles-item">
                                {t('Stripe subscription')}:
                              </div>
                            )}

                            {record.priceSource && (
                              <div className="ContractDetailsSpecifics__info-titles-item">
                                {record.priceSource === 'V4PricingTool' ? t('V4 Product ID') : t('Template ID')}:
                              </div>
                            )}
                            <div className="ContractDetailsSpecifics__info-titles-item">{t('Price source')}:</div>
                            <div className="ContractDetailsSpecifics__info-titles-item">{t('Calculation method')}:</div>
                          </div>
                        </div>
                        {/* -- */}
                        <div className="ContractDetailsSpecifics__info">
                          <div className={classNames(classes.sharperText, 'ContractDetailsSpecifics__info-titles')}>
                            <div>
                              <div>
                                {record.stripeCustomerLink && (
                                  <a href={record.stripeCustomerLink} target="_blank" rel="noopener noreferrer">
                                    {t('Link')}
                                  </a>
                                )}
                              </div>
                              <div>
                                {record.stripeSubscriptionLink && (
                                  <a href={record.stripeSubscriptionLink} target="_blank" rel="noopener noreferrer">
                                    {t('Link')}
                                  </a>
                                )}
                              </div>
                            </div>
                            <div
                              className={classes.templateIdValue}
                              title={
                                t('The internal name and ID number of this template') +
                                ' (' +
                                t('ID 0 means that this contract has no template') +
                                ')'
                              }
                            >
                              {record
                                ? `"${record.contractName}" (#${
                                    record.contractTemplateId ? record.contractTemplateId : record.serviceVariantId
                                  })`
                                : ''}
                            </div>
                            <div title={t("The source the contract's prices are based upon")}>
                              "{record && record?.priceSource ? record.priceSource : ''}"
                            </div>
                            <div title={t('Which start date to use for price calculation')}>
                              {'"'}
                              {record.calculationMethod === 200
                                ? t('Contract Creation Date')
                                : t('First Registration Date')}
                              {'" '}({record.calculationMethod})
                            </div>
                          </div>
                        </div>
                        {/* -- Second Col. Names -- */}
                        <div className="ContractDetailsSpecifics__info">
                          <div className={classNames(classes.sharperText, 'ContractDetailsSpecifics__info-titles')}>
                            <div>
                              {record.prettyIdentifier && (
                                <div
                                  className="ContractDetailsSpecifics__info-titles-item"
                                  title="Full/Pretty-Identifier"
                                >
                                  {t('Full Identifier')}:
                                </div>
                              )}
                              <div className="ContractDetailsSpecifics__info-titles-item">{'Contract State'}:</div>

                              <div
                                className="ContractDetailsSpecifics__info-titles-item"
                                title="Note: Do not expose this with 'normal' users (use the full/pretty-Identifier instead)."
                              >
                                {t('Internal ID')}:
                              </div>
                              {typeTranslations && (
                                <div
                                  className="ContractDetailsSpecifics__info-titles-item"
                                  title="Product Max End-Mileage limit"
                                >
                                  Product Max End {typeTranslations.value}:
                                </div>
                              )}
                            </div>
                            <div className="ContractDetailsSpecifics__info-titles-item" />
                          </div>
                        </div>
                        {/* -- Second Col. Data -- */}
                        <div className="ContractDetailsSpecifics__info">
                          <div className={classNames(classes.sharperText, 'ContractDetailsSpecifics__info-titles')}>
                            <div>
                              <div
                                title="Full/Pretty-Identifier"
                                onClick={() => this.copyToClipboard(record.prettyIdentifier)}
                              >
                                {record.prettyIdentifier}
                              </div>
                              <div title={'The state code of this contract'}>
                                {`"${contractStateName}" (${contractState})`}
                              </div>
                              <div onClick={() => this.copyToClipboard(record.serviceContractId)}>
                                {!record.serviceContractId ? '-' : record.serviceContractId}
                              </div>
                              {
                                <div>
                                  {
                                    !record.maxEndMileage
                                      ? '-'
                                      : formatMileage(record.maxEndMileage, {
                                          symbolDisplayType: 'APPEND',
                                        }) /* Product max endMileage/value */
                                  }
                                </div>
                              }
                            </div>
                            <div />
                          </div>
                        </div>
                      </div>
                      {/* --  -- */}
                      <div className={classNames('ContractDetailsSpecifics__downloads')}>
                        <h3>Additional files with the contract</h3>
                        {htmlFilesContainer}
                        <div />
                      </div>
                      <div>
                        <br />
                      </div>
                    </div>
                  )}
                  {/* ---------- */}

                  {record && showAdditionals && (
                    <>
                      <Typography className={classes.cardSubtitle} variant="display1">
                        <span className={classes.cardSubtitleText}>{t('Options purchased to the subscription')}</span>
                      </Typography>
                      <div className={classes.additionalsList}>
                        <IncludedOptionsList
                          options={record.includedOptions}
                          filterBy="ADDITIONAL"
                          showAbbreviation={true}
                          useDefaultAbbreviationClass={true}
                        />
                      </div>
                    </>
                  )}
                  {record && showIncluded && (
                    <>
                      <Typography className={classes.cardSubtitle} variant="display1">
                        <span className={classes.cardSubtitleText} style={{ marginTop: '8px' }}>
                          {t('Included in subscription')}
                        </span>
                      </Typography>
                      <div className={classes.additionalsList}>
                        <IncludedOptionsList
                          options={record.includedOptions}
                          filterBy="PROPERTY"
                          showAbbreviation={false}
                          useDefaultAbbreviationClass={true}
                        />
                      </div>
                    </>
                  )}
                  {record && record.customTerms && (
                    <div className="ContractDetailsSpecifics__customTerms">
                      <Typography className={classes.cardSubtitle} variant="display1">
                        <span className={classes.cardSubtitleText}>{t('Custom Terms')}</span>
                      </Typography>
                      <Typography variant="body1">{record.customTerms}</Typography>
                    </div>
                  )}
                </CardContent>
              </Card>
            </PanelContent>
          </Panel>
        )}
      </AppContext.Consumer>
    )
  }

  private renderfilesContainer = (filesContainer: any): JSX.Element[] => {
    const html: JSX.Element[] = []

    if (filesContainer && filesContainer.length > 0) {
      const itemToHtml = (name: string, url: string) => {
        return (
          <>
            <div>
              <b>Name:</b> "{name}" <b>URL:</b>"
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
              "
            </div>
          </>
        )
      }

      filesContainer.forEach((item: { name: string; v4URL: string }) => {
        html.push(
          <>
            {itemToHtml(item?.name, item?.v4URL)}
            <br />
          </>,
        )
      })
    } else {
      html.push(<i>-- No files --</i>)
    }

    return html
  }

  handleStartDateEdit = () => this.setState({ editStartDate: true })

  getStartDateFromRecord = (props?: TProps): string => {
    const { record } = props || this.props
    return record ? formatDate(record.startDate) : ''
  }

  handleStartDateEditCancel = () =>
    this.setState({ editStartDate: false, editableStartDate: this.getStartDateFromRecord() })

  handleStartDateSubmit = (country: string) => async () => {
    const { editableStartDate } = this.state
    const { id, initContractSpecifics } = this.props
    const parsedDate = parseDate(editableStartDate, country)

    if (!parsedDate) {
      return
    }

    const { statusCode } = await updateContractStartDate(parsedDate.toISOString(), id)

    if (statusCode === 200) {
      this.handleStartDateEditCancel()
      initContractSpecifics()
    }
  }

  handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ editableStartDate: e.target.value })

  private copyToClipboard = (e: any) => {
    navigator.clipboard.writeText(e)
  }

  private getDurationWithValues = (
    typeTranslations: IValueTypeTranslations | undefined,
    isProductContract: boolean,
  ): string => {
    const { record } = this.props

    if (record && typeTranslations) {
      const mileage = record.mileage ? record.mileage : record.value
      const value = isProductContract ? record.value : mileage

      if (record.valueType === 'Mileage') {
        // Mileage for vehicles
        return value !== undefined && value !== null
          ? ` / ${formatMileage(value, {
              symbolDisplayType: 'APPEND',
            })}`
          : ''
      } else if (value !== undefined && value !== null) {
        return `/ ${value} ${t(typeTranslations.shortValue)}`
      }
    }

    return ''
  }

  private initData = async () => {
    this.pdfAgreementUrl = this.props.fromReportLink
      ? getPdfAgreementUrlReportLink(this.props.id)
      : getPdfAgreementUrl(this.props.id)
  }

  private contractHasAdditionalOptions = (contract: IContractDetailsRecord) => {
    return contract.includedOptions && contract.includedOptions.find((c) => c.assoc === 'ADDITIONAL') !== undefined
  }

  private contractHasIncludedOptions = (contract: IContractDetailsRecord) => {
    return contract.includedOptions && contract.includedOptions.find((c) => c.assoc === 'PROPERTY') !== undefined
  }

  private mapPaymentGateways(gateway: PaymentGateway): string {
    switch (gateway) {
      case 'B2B':
        return t('EDI charge')
      case 'NONE':
        return t('Manual Collection')
      case 'Stripe':
        return t('Credit card (standard)')
      case 'V4':
        return t('One-time Dealer Payment')
      default:
        return 'Undefined'
    }
  }
}

export default compose<TProps, IOwnProps>(withContractDisplayConfig, withStyles(styles))(ContractDetailsSpecifics)

import { Chip } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { formatDate } from '@omnicar/sam-format'
import { ContractState, IBasicContractInfo } from '@omnicar/sam-types'
import { getPendingTerminationDate } from 'api/api'
import classNames from 'classnames'
import SuspendTooltip from 'components/admin/Contract/Details/ActionList/SuspendReason/SuspendTooltip'
import Tooltip from 'components/admin/Contract/Details/Tooltip'
import Typography from 'components/Typography'
import moment from 'moment'
import React from 'react'
import { palette } from 'theme'
import { t, tContractState } from 'translations/translationFunctions'

interface IOwnProps {
  id: string
  basicInfo: IBasicContractInfo | undefined
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  loading: boolean
  loaded: boolean
  pendingTerminationDate?: Date
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    info: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    title: {
      color: theme.palette.primary.contrastText,
    },
    health: {
      backgroundColor: 'transparent',
      borderRadius: '100%',
      height: theme.spacing(2),
      marginRight: theme.spacing(1),
      width: theme.spacing(2),
    },
    'health-healthy': {
      backgroundColor: '#008000',
    },
    options: {
      display: 'flex',
      flexDirection: 'row',
    },
    option: {
      color: palette.text.lighter,
      marginRight: theme.spacing(1),
    },
    contractStateLabel: {
      color: '#eee',
    },
    contractState: {
      height: theme.spacing(3),
      marginLeft: theme.spacing(2),
    },
    contractState100: {
      backgroundColor: palette.contractStates.offer[500],
    },
    contractState100Color: {
      color: palette.contractStates.offer[500],
    },
    contractState110: {
      backgroundColor: palette.contractStates.offerExpired[500],
    },
    contractState120: {
      backgroundColor: palette.contractStates.offerExpired[500],
    },
    contractState130: {
      backgroundColor: palette.contractStates.offerExpired[500],
    },
    contractState200: {
      backgroundColor: palette.contractStates.active[500],
    },
    contractState210: {
      backgroundColor: palette.contractStates.active[500],
    },
    contractState250: {
      backgroundColor: palette.contractStates.active[500],
    },
    contractState400: {
      backgroundColor: palette.contractStates.paymentFailed[500],
    },
    contractState450: {
      backgroundColor: palette.contractStates.cancelledSubsctiption[500],
    },
    contractState500: {
      backgroundColor: palette.contractStates.contractTerminated[500],
    },
    contractState510: {
      backgroundColor: palette.contractStates.contractTerminated[500],
    },
    contractState600: {
      backgroundColor: palette.contractStates.contractExpired[500],
    },
    contractState900: {
      backgroundColor: palette.contractStates.contractSettled[500],
    },
    contractState950: {
      backgroundColor: palette.contractStates.contractSettled[500],
    },
    contractStateWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
  })

class Title extends React.Component<TProps, IState> {
  public state: IState = {
    loading: false,
    loaded: false,
    pendingTerminationDate: undefined,
  }

  public componentDidUpdate(prevProps: TProps) {
    const { id, basicInfo } = this.props

    if (id !== prevProps.id || prevProps.basicInfo?.contractState !== basicInfo?.contractState) {
      this.fetchPendingTerminationDate()
    }
  }

  public render() {
    const { classes, basicInfo } = this.props
    const healthModifier = this.getHealthModifier()
    const showHealth = false

    return (
      <div className={classes.root}>
        <div className={classes.info}>
          {showHealth && <div className={classNames(classes.health, classes[`health-${healthModifier}`])} />}
          {basicInfo && (
            <>
              {basicInfo.contractName}
              <Chip
                label={
                  <div className={classes.contractStateWrapper}>
                    <Typography className={classes.contractStateLabel} variant="button-medium">
                      {tContractState(basicInfo.contractState)}
                    </Typography>
                    {this.renderStateTooltip()}
                  </div>
                }
                classes={{
                  root: classNames(classes.contractState, classes[`contractState${basicInfo.contractState}`]),
                }}
              />
            </>
          )}
        </div>
        <div className={classes.options}>
          {basicInfo &&
            basicInfo.additionalOptions &&
            basicInfo.additionalOptions
              .filter((o) => o.assoc === 'ADDITIONAL')
              .map((option) => (
                <div key={`ContractDetailsPageOptionKey-${option.id}`}>
                  <Typography className={classes.option} variant="body1">
                    {option.abbreviation}
                  </Typography>
                </div>
              ))}
        </div>
      </div>
    )
  }

  fetchPendingTerminationDate = async () => {
    const { id, basicInfo } = this.props

    try {
      if (basicInfo?.contractState === ContractState.ActivePendingTermination) {
        const res = await getPendingTerminationDate(id)
        res.statusCode === 200 && res.data && this.setState({ pendingTerminationDate: new Date(res.data) })
      } else {
        this.setState({ pendingTerminationDate: undefined })
      }
    } catch (error) {
      console.error(`PendingTerminationDate: ${error}`)
    }
  }

  private getHealthModifier = () => {
    return 'healthy'
  }

  private renderStateTooltip = () => {
    const { basicInfo, id } = this.props
    const { pendingTerminationDate } = this.state
    let tooltipElement: JSX.Element | undefined

    if (!basicInfo) {
      return
    }

    const { contractState, offerExpirationDate } = basicInfo

    if ([ContractState.Suspended, ContractState.Terminated, ContractState.Expired].includes(contractState)) {
      tooltipElement = <SuspendTooltip prettyId={id} contractState={contractState} />
    }

    if (contractState === ContractState.ActivePendingTermination && pendingTerminationDate) {
      tooltipElement = (
        <Tooltip
          infoSections={[
            {
              title: t('Contract will be closed on'),
              value: formatDate(pendingTerminationDate),
            },
          ]}
          title={t('When?')}
          showAsPopup
        />
      )
    }

    if (contractState === ContractState.Offer && offerExpirationDate) {
      tooltipElement = (
        <Tooltip
          title={t('When?')}
          infoSections={[
            {
              title: `${t('Validity period')}:`,
              value: t('The offer is valid until %expirationDate at the latest', {
                expirationDate: formatDate(moment(basicInfo.offerExpirationDate).toDate()),
              }),
            },
          ]}
          showAsPopup
        />
      )
    }

    return tooltipElement
  }
}

export default withStyles(styles)(Title)

import { Button, Dialog, DialogContent, TextField, WithStyles, withStyles } from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import { IAdminUserInfo, TIsoCountry } from '@omnicar/sam-types'
import { verifyPassword } from 'api/api'
import SpinnerButton from 'components/Mui/SpinnerButton'
import Typography from 'components/Typography'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React, { ChangeEvent, Component, FocusEvent, KeyboardEvent } from 'react'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'
import { preventEventTrigger } from 'utils/formik'
import { getProviderCountry } from 'utils/localStorage'
import { emailPattern } from 'utils/regex'
import * as Yup from 'yup'
import styles from './styles'
import { getCityByZip } from 'utils/zipCity'

interface IProps extends WithStyles<typeof styles> {
  user: IAdminUserInfo
  onFormDirty: (dirty: boolean) => void
  onSubmit: (user: IAdminUserInfo) => void
  onCancel: () => void
}

interface IState {
  dialogOpen?: boolean
  passwordCheckLoading: boolean
}

interface IAccountSettingsFormValues extends IAdminUserInfo {
  retypeEmail: string
  password: string
}

class AccountSettingsUserForm extends Component<IProps, IState> {
  state: Readonly<IState> = {
    passwordCheckLoading: false,
  }

  private validationSchema = Yup.object().shape({
    name: Yup.string().required(t('The name is required')),
    email: Yup.string()
      .required(t('Email address is required'))
      .matches(emailPattern, t('Invalid email address'))
      .max(255, t('The email address cannot be more than %count characters', { count: 255 })),
    retypeEmail: Yup.string(),
    phone: Yup.string()
      .required(t('Phone is required'))
      .matches(/^[0-9 +#-]{6,20}$/, t('Invalid phone number')),
    zip: Yup.string().required(t('Zip is required')),
    city: Yup.string().required(t('City is required')),
    password: Yup.string(),
  })

  private country: TIsoCountry = 'DK'

  public componentDidMount() {
    const country = getProviderCountry()
    if (country) {
      this.country = country as TIsoCountry
    }
  }

  public render() {
    const { classes, user, onFormDirty } = this.props
    const { passwordCheckLoading } = this.state
    return (
      <Formik
        initialValues={{ ...user, retypeEmail: '', password: '' }}
        validate={this.validate}
        validationSchema={this.validationSchema}
        // tslint:disable-next-line jsx-no-lambda
        onSubmit={this.handleSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          dirty,
          isValid,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setTouched,
          isSubmitting,
          submitForm,
          validateForm,
          setFieldError,
        }: FormikProps<IAccountSettingsFormValues>) => {
          return (
            <>
              <Form>
                {/* <div className={`ActionBar ${classes.actionBar}`}>
                  <Typography variant="subheading">{t('User details')}</Typography>
                </div> */}
                <div className={`Form ${classes.form}`}>
                  <div>
                    <AppContext.Consumer>
                      {({ isSuperAdmin, role }) => (
                        <>
                          <TextField
                            name="name"
                            label={t('Name')}
                            className={classes.input}
                            value={values.name}
                            onChange={handleChange}
                            // tslint:disable-next-line jsx-no-lambda
                            // onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                            onBlur={(e: FocusEvent<HTMLInputElement>) => {
                              onFormDirty(dirty)
                              handleBlur(e)
                            }}
                            disabled={role !== 'admin' && !isSuperAdmin}
                          />
                          <Typography variant="body2" className={classes.inputError}>
                            {errors.name && touched.name && errors.name}
                          </Typography>
                          <TextField
                            name="email"
                            label={t('Email')}
                            className={classes.input}
                            value={values.email}
                            onChange={handleChange}
                            // tslint:disable-next-line jsx-no-lambda
                            onBlur={(e: FocusEvent<HTMLInputElement>) => {
                              onFormDirty(dirty)
                              handleBlur(e)
                            }}
                            disabled={role !== 'admin' && !isSuperAdmin}
                          />
                          <Typography variant="body2" className={classes.inputError}>
                            {errors.email && touched.email && errors.email}
                          </Typography>
                          {!isSubmitting && touched.email && this.props.user.email !== values.email && (
                            <>
                              <TextField
                                name="retypeEmail"
                                autoComplete="off"
                                label={t('Re-type email')}
                                className={classes.input}
                                value={values.retypeEmail}
                                onChange={handleChange}
                                onCut={preventEventTrigger}
                                onCopy={preventEventTrigger}
                                onPaste={preventEventTrigger}
                                // tslint:disable-next-line jsx-no-lambda
                                onBlur={(e: FocusEvent<any>) => {
                                  onFormDirty(dirty)
                                  handleBlur(e)
                                }}
                                fullWidth={true}
                                inputProps={{
                                  autoComplete: 'new-password',
                                }}
                              />
                              <Typography variant="body2" className={classes.inputError}>
                                {errors.retypeEmail && touched.retypeEmail && errors.retypeEmail}
                              </Typography>
                            </>
                          )}
                        </>
                      )}
                    </AppContext.Consumer>
                    <TextField
                      name="phone"
                      label={t('Phone')}
                      className={classes.input}
                      value={values.phone}
                      onChange={handleChange}
                      // tslint:disable-next-line jsx-no-lambda
                      onBlur={(e: FocusEvent<HTMLInputElement>) => {
                        onFormDirty(dirty)
                        handleBlur(e)
                      }}
                    />
                    <Typography variant="body2" className={classes.inputError}>
                      {errors.phone && touched.phone && errors.phone}
                    </Typography>
                  </div>
                  <div>
                    <AppContext.Consumer>
                      {({ providerInfo }) => (
                        <>
                          <TextField
                            name="zip"
                            label={t('Zipcode')}
                            className={classes.input}
                            value={values.zip}
                            // tslint:disable-next-line jsx-no-lambda
                            onChange={handleChange}
                            // tslint:disable-next-line jsx-no-lambda
                            onBlur={(e: FocusEvent<HTMLInputElement>) => {
                              onFormDirty(dirty)
                              this.handleZipBlur(e, setFieldValue)
                              handleBlur(e)
                            }}
                          />
                          <Typography variant="body2" className={classes.inputError}>
                            {errors.zip && touched.zip && errors.zip}
                          </Typography>
                        </>
                      )}
                    </AppContext.Consumer>

                    <TextField
                      name="city"
                      label={t('City')}
                      className={classes.input}
                      value={values.city}
                      onChange={handleChange}
                      // tslint:disable-next-line jsx-no-lambda
                      onBlur={(e: FocusEvent<HTMLInputElement>) => {
                        onFormDirty(dirty)
                        handleBlur(e)
                      }}
                    />
                    <Typography variant="body2" className={classes.inputError}>
                      {errors.city && touched.city && errors.city}
                    </Typography>
                  </div>
                </div>
                <div className={classes.buttonGroupSettings}>
                  <SpinnerButton
                    className={classes.btnSave}
                    color="primary"
                    variant="contained"
                    disabled={!dirty && !isValid}
                    onClick={async () => {
                      const validationResult = await validateForm(values)
                      if (!!validationResult && Object.values(validationResult).every((v) => v === undefined)) {
                        this.openPasswordCheckDialog()
                      }
                    }}
                    showSpinner={isSubmitting}
                    IconComponent={CheckIcon}
                  >
                    {t('Save')}
                  </SpinnerButton>
                  {/* <Button
                    variant="contained"
                    disabled={(!dirty && !isValid) || isSubmitting}
                    type="reset"
                    onClick={onCancel}
                  >
                    <ResetFormIcon className={classes.btnIcon} />
                    {t('Restore')}
                  </Button> */}
                </div>
              </Form>
              <Dialog open={!!this.state.dialogOpen}>
                <DialogContent className={classes.dialogContent}>
                  <Typography variant="body2">{t('Please enter your password to update your user details')}</Typography>
                  <TextField
                    autoFocus={true}
                    helperText={touched.password && errors.password && errors.password}
                    error={errors.password && touched.password ? true : false}
                    label={t('Enter your password')}
                    fullWidth={true}
                    name="password"
                    disabled={false}
                    onChange={(e: React.ChangeEvent<any>) => {
                      handleChange(e)
                      setFieldError('password', undefined)
                    }}
                    onBlur={handleBlur}
                    onCut={preventEventTrigger}
                    onCopy={preventEventTrigger}
                    onPaste={preventEventTrigger}
                    onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
                      // Trigger password validation and sumbit if enter was pressed
                      if (e.which === 13 && !isSubmitting && !passwordCheckLoading) {
                        this.validateAndVerifyPassword({ password: values.password }, submitForm, setFieldError)
                      }
                    }}
                    type="password"
                    value={values.password}
                  />
                  <div className={classes.buttonGroup}>
                    <SpinnerButton
                      color="primary"
                      variant="contained"
                      // tslint:disable-next-line:jsx-no-lambda
                      onClick={() =>
                        this.validateAndVerifyPassword({ password: values.password }, submitForm, setFieldError)
                      }
                      showSpinner={passwordCheckLoading}
                      disabled={isSubmitting || passwordCheckLoading}
                    >
                      {t('Verify password')}
                    </SpinnerButton>
                    <Button
                      variant="contained"
                      // tslint:disable-next-line:jsx-no-lambda
                      onClick={() => this.handleClosePasswordCheckDialog(setFieldValue)}
                      disabled={isSubmitting}
                    >
                      {t('Cancel')}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          )
        }}
      </Formik>
    )
  }

  private validateAndVerifyPassword = async (
    values: { password: string },
    handleSubmit: () => Promise<void>,
    setFieldError: (field: string, message: string | undefined) => void,
  ) => {
    this.setState({ passwordCheckLoading: true }, async () => {
      if (!values.password || values.password.length < 6) {
        setFieldError('password', t('Password must have at least %count characters', { count: 6 }))
        return
      }

      const res = await verifyPassword(values)
      if (res.statusCode !== 201) {
        return
      }

      await handleSubmit()
    })
    this.setState({ passwordCheckLoading: false })
  }

  private openPasswordCheckDialog = () => {
    this.setState({ dialogOpen: true })
  }

  private handleClosePasswordCheckDialog = (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    setFieldValue('password', '')
    this.setState({ dialogOpen: false })
  }

  private validate = (values: IAccountSettingsFormValues) => {
    let errors: Partial<IAccountSettingsFormValues> = {}
    // Only set re-type email error if email was changed
    if (values.email !== this.props.user.email && (!values.retypeEmail || values.retypeEmail !== values.email)) {
      errors.retypeEmail = t('Emails do not match')
    }
    return errors
  }

  private handleZipBlur = async (
    e: ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, val: string) => void,
  ) => {
    const zipcode: string = e.currentTarget.value
    const city = await getCityByZip(this.country, zipcode)

    if (city) {
      setFieldValue('city', city)
    }
  }

  private handleSubmit = async (
    values: IAccountSettingsFormValues,
    formikActions: FormikHelpers<IAccountSettingsFormValues>,
  ) => {
    const parameters = this.mapFormValuesToIAdminUserInfo(values)
    const { setSubmitting } = formikActions
    setSubmitting(true) // todo: show spinner
    this.props.onSubmit(parameters)
    setSubmitting(false)
  }

  private mapFormValuesToIAdminUserInfo = (formValues: IAccountSettingsFormValues): IAdminUserInfo => {
    return {
      name: formValues.name,
      phone: formValues.phone,
      address: formValues.address,
      zip: formValues.zip,
      city: formValues.city,
      email: formValues.email,
    }
  }
}

export default withStyles(styles)(AccountSettingsUserForm)

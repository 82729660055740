import { Button, CardContent, CircularProgress, InputAdornment, TextField } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import {
  AccountCircle as AccountCircleIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  Email as EmailIcon,
  LocationCity as LocationCityIcon,
  LocationOn as LocationOnIcon,
  Phone as PhoneIcon,
  Save as SaveIcon,
  Work as WorkIcon,
} from '@material-ui/icons'
import { formatPhone } from '@omnicar/sam-format'
import { IJsonStatus } from '@omnicar/sam-tfetch'
import {
  ApiError,
  CustomerUpdateResponse,
  IAdminCustomer,
  ICustomerPasswordChangeRequest,
  TIsoCountry,
} from '@omnicar/sam-types'
import { getCustomer, updateCustomerPassword } from 'api/api'
import BriefcaseIcon from 'assets/icons/BriefcaseIcon'
import classNames from 'classnames'
import BounceIcon from 'components/Mui/BounceIcon'
import { Card } from 'components/Mui/Card'
import { Panel, PanelActions, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import Typography from 'components/Typography'
import { Formik, FormikProps } from 'formik'
import memoize from 'lodash.memoize'
import React from 'react'
import { Redirect } from 'react-router'
import { compose } from 'recompose'
import { pageNotFoundPath } from 'routes/paths'
import { AppContext } from 'store/appContext'
import { createPanelStyles, theme as customTheme } from 'theme'
import { t } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import bounceMessages from 'utils/bounce'
import { CustomerFieldName, validateCustomer } from 'utils/formik'
import { getProviderCountry } from 'utils/localStorage'
import notify from 'utils/notify/notify'
import CustomerPasswordForm from './CustomerPasswordForm'
import { getCityByZip } from 'utils/zipCity'

interface IOwnProps {
  allowEdit?: boolean
  allowPasswordChange?: boolean
  customerIdentifier: string // Customer pretty identifier.
  contractPrettyId: string // Contract pretty id.
  customerRecord: IAdminCustomer
  onClick?: () => void
  onPatchCallback: (
    customerPrettyId: string,
    record: IAdminCustomer,
  ) => Promise<IJsonStatus<CustomerUpdateResponse, ApiError>>
  onUpdateCustomerNameState?: (customerName: string) => any
  onUpdateCustomerDetailsState?: (record: IAdminCustomer) => any
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    subheading: {
      marginTop: theme.spacing(3),
      marginBottom: '5px',
    },
    button: {
      marginLeft: theme.spacing(2),
    },
    bounceIcon: {
      marginLeft: '3px',
      marginRight: '3px',
      marginTop: '2px',
    },
    bounceContainer: {
      display: 'flex',
      marginTop: '-20px',
      marginLeft: '25px',
      marginBottom: '10px',
    },
    bounceText: {
      fontSize: 'small',
      paddingLeft: '5px',
    },
    errorText: {
      color: theme.palette.error.main,
    },
    warningText: {
      color: customTheme.palette.context.attention[600],
    },
    customerFormContainer: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    passwordResetContainer: {
      minWidth: '240px',
      padding: '5px',
      marginLeft: '30px',
      maxHeight: '40vh',
      [theme.breakpoints.down('sm')]: {
        margin: '15px',
      },
    },
    passwordResetHeader: {
      marginLeft: '20px',
    },
    // container: {
    //   paddingBottom: '8px',
    // },
    cardContentTitleRow: {
      height: '5rem',
    },
  })

type TProps = IOwnProps & WithStyles<typeof styles>

interface IFormValues extends IAdminCustomer {
  cancelCount: number
}

interface IState {
  edit: boolean
  loading: boolean
  hasLoaded: boolean // To make sure when to un-disable the Edit button etc.
  record: IAdminCustomer
  title: string
  invalidCustomer: boolean
  cancelCount: number
  extSysCustomerUpdateMessage?: string
}

class CustomerDetails extends React.Component<TProps, IState> {
  public state: IState = {
    edit: false,
    loading: false,
    hasLoaded: false,
    title: '',
    invalidCustomer: false,
    record: {
      name: '',
      email: '',
      emailBounceType: undefined,
      phone: '',
      address: '',
      city: '',
      zip: '',
      customerType: 'PRIVATE',
    },
    cancelCount: 0,
  }

  private fields: (
    classes: ClassNameMap,
  ) => Record<CustomerFieldName, { label: TranslationKey; icon: React.ReactElement<any> }> = memoize(
    (classes: ClassNameMap) => ({
      cvr: {
        label: 'CVR',
        icon: <BriefcaseIcon className={classes.inputIcon} />,
      },
      companyName: {
        label: 'Company name',
        icon: <WorkIcon className={classes.inputIcon} />,
      },
      email: {
        label: 'Email',
        icon: <EmailIcon className={classes.inputIcon} />,
      },
      name: {
        label: 'Name',
        icon: <AccountCircleIcon className={classes.inputIcon} />,
      },
      ean: {
        label: 'EAN',
        icon: <BriefcaseIcon className={classes.inputIcon} />,
      },
      phone: {
        label: 'Phone',
        icon: <PhoneIcon className={classes.inputIcon} />,
      },
      address: {
        label: 'Address',
        icon: <LocationOnIcon className={classes.inputIcon} />,
      },
      zip: {
        label: 'Postal code',
        icon: <LocationCityIcon className={classes.inputIcon} />,
      },
      city: {
        label: 'City',
        icon: <LocationCityIcon className={classes.inputIcon} />,
      },
    }),
  )

  private country: TIsoCountry = 'DK'

  public componentDidMount() {
    this.initData()
    const country = getProviderCountry()

    if (country) {
      this.country = country as TIsoCountry
    }
  }

  public componentDidUpdate(prevProps: TProps) {
    if (
      this.props?.customerRecord !== prevProps?.customerRecord ||
      this.props.customerIdentifier !== prevProps.customerIdentifier ||
      this.props?.customerRecord?.email !== prevProps?.customerRecord?.email
    ) {
      this.initData()
    }
  }

  private initData = async () => {
    const { onUpdateCustomerNameState, customerIdentifier, customerRecord } = this.props

    this.setState({ loading: true })

    let data: IAdminCustomer | undefined = undefined

    if (customerRecord) {
      data = customerRecord
    } else if (customerIdentifier) {
      const response = await getCustomer(customerIdentifier)
      data = response?.data
    }

    if (data) {
      if (onUpdateCustomerNameState) {
        onUpdateCustomerNameState(data.name)
      }

      this.setState({
        edit: false,
        record: data,
        hasLoaded: true,
        title: data.customerType === 'BUSINESS' ? data.companyName || data.name : data.name,
      })

      this.props.onUpdateCustomerDetailsState && this.props.onUpdateCustomerDetailsState(data)
      this.setState({
        loading: false,
      })
    } else {
      this.setState({ loading: false })
    }
  }

  public render() {
    const { classes, allowEdit, allowPasswordChange } = this.props
    const { edit, record, hasLoaded, title, invalidCustomer, cancelCount } = this.state

    const isRenderLoading: boolean = this.state.loading || !record.email

    if (invalidCustomer) {
      return <Redirect to={pageNotFoundPath} />
    }

    const allowEditing = allowEdit !== undefined ? allowEdit : true
    const initialValues: IFormValues = { ...record, cancelCount }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSaveButton}
        enableReinitialize={true}
        validate={this.validate}
      >
        {(formikProps: FormikProps<IAdminCustomer>) => (
          <Panel>
            <PanelHeader>
              <PanelTitle>{t('Customer')}</PanelTitle>
              <PanelActions>
                {!edit && allowEditing && (
                  <Button
                    className={classes.panelActionsButton}
                    onClick={() => {
                      formikProps.resetForm()
                      this.handleEdit()
                    }}
                    disabled={!hasLoaded || isRenderLoading}
                    size="small"
                    variant="outlined"
                  >
                    <EditIcon className={classes.panelActionsButtonIcon} />
                    {t('Edit')}
                  </Button>
                )}
                {edit && allowEdit && (
                  <React.Fragment>
                    <Button
                      className={classNames(classes.panelActionsButton, classes.button)}
                      onClick={formikProps.submitForm}
                      disabled={
                        !hasLoaded ||
                        isRenderLoading ||
                        !formikProps.dirty ||
                        Object.keys(formikProps.errors).length !== 0
                      }
                      size="small"
                      variant="outlined"
                      color="secondary"
                    >
                      <SaveIcon className={classes.panelActionsButtonIcon} />
                      {t('Save')}
                    </Button>
                    <Button
                      className={classNames(classes.panelActionsButton, classes.button)}
                      onClick={this.handleCancel}
                      disabled={!hasLoaded || isRenderLoading}
                      size="small"
                      variant="outlined"
                    >
                      <CancelIcon className={classes.panelActionsButtonIcon} />
                      {t('Cancel')}
                    </Button>
                  </React.Fragment>
                )}
              </PanelActions>
            </PanelHeader>
            <PanelContent>
              <Card>
                <div className={classes.card}>
                  <CardContent>
                    <div className={classes.cardContentTitleRow} title={t('Customer')}>
                      <Typography className={classes.cardTitle} variant="title">
                        {formikProps.values.customerType === 'BUSINESS' ? (
                          <WorkIcon className={classes.cardTitleIcon} />
                        ) : (
                          <AccountCircleIcon className={classes.cardTitleIcon} />
                        )}
                        <span className={classes.cardTitleText}>{title}</span>
                      </Typography>
                      {isRenderLoading && <CircularProgress size={32} style={{ color: '#111111' }} />}
                    </div>
                    {!edit ? (
                      <div className={classes.container}>
                        <React.Fragment>
                          <div className={classes.presentationField} title={t('Phone number')}>
                            <PhoneIcon className={classes.presentationIcon} />
                            {record && formatPhone(record.phone)}
                          </div>
                          <div className={classes.presentationField} title={t('Email')}>
                            <EmailIcon className={classes.presentationIcon} />
                            {record && record.email}
                          </div>
                          <div>
                            {record && record.emailBounceType && (
                              <div className={classes.bounceContainer}>
                                <BounceIcon
                                  className={classNames(classes.presentationIcon, classes.bounceIcon)}
                                  bounceType={record.emailBounceType}
                                  overrideColor={true}
                                />
                                <Typography
                                  className={classNames(
                                    classes.bounceText,
                                    record.emailBounceType === 'SoftBounce' ? classes.warningText : classes.errorText,
                                  )}
                                >
                                  {t(bounceMessages[record.emailBounceType] || bounceMessages['Unknown'])}
                                </Typography>
                              </div>
                            )}
                          </div>
                          <div className={classes.presentationField} title={t('Address')}>
                            <LocationOnIcon className={classes.presentationIcon} />
                            {record && record.address}
                          </div>
                          <div className={classes.presentationField} title={t('City')}>
                            <LocationCityIcon className={classes.presentationIcon} />
                            {record && `${record.zip} ${record.city}`}
                          </div>
                        </React.Fragment>
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className={classes.customerFormContainer}>
                          <div>
                            {formikProps.values.customerType === 'BUSINESS' ? (
                              <React.Fragment>
                                {this.getField('cvr', formikProps)}
                                {this.getField('companyName', formikProps)}
                                {this.getField('address', formikProps)}
                                {this.getField('zip', formikProps)}
                                {this.getField('city', formikProps)}
                                {this.getField('ean', formikProps)}
                                <Typography className={classes.subheading} variant="subheading">
                                  {t('Contact')}
                                </Typography>
                                {this.getField('email', formikProps)}
                                {this.getField('phone', formikProps)}
                                {this.getField('name', formikProps)}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {this.getField('email', formikProps)}
                                {this.getField('name', formikProps)}
                                {this.getField('phone', formikProps)}
                                {this.getField('address', formikProps)}
                                {this.getField('zip', formikProps)}
                                {this.getField('city', formikProps)}
                              </React.Fragment>
                            )}
                          </div>
                          <AppContext.Consumer>
                            {({ isSuperAdmin }) =>
                              isSuperAdmin &&
                              !!allowPasswordChange && (
                                <Card className={classes.passwordResetContainer}>
                                  <Typography className={classes.passwordResetHeader} variant="body1">
                                    {t('MyPage password')}
                                  </Typography>
                                  <CardContent>
                                    <CustomerPasswordForm onSubmit={this.handlePasswordSubmit} />
                                  </CardContent>
                                </Card>
                              )
                            }
                          </AppContext.Consumer>
                        </div>
                      </React.Fragment>
                    )}
                  </CardContent>
                </div>
              </Card>
            </PanelContent>
          </Panel>
        )}
      </Formik>
    )
  }

  private handlePasswordSubmit = async (body: ICustomerPasswordChangeRequest) => {
    const res = await updateCustomerPassword(body, this.props.customerIdentifier)
    const successful = res.statusCode && [200, 201, 204].includes(res.statusCode)
    if (successful) {
      notify.success({ message: t('Password changed') })
    }

    return successful
  }

  private getField = (field: CustomerFieldName, formikProps: FormikProps<IAdminCustomer>) => {
    const { classes } = this.props
    const fieldConf = this.fields(classes)[field]
    const { touched, errors, handleBlur, handleChange, setFieldValue, values } = formikProps
    return (
      <TextField
        className={classes.inputField}
        margin={'dense'}
        fullWidth={true}
        type={'text'}
        label={t(fieldConf.label)}
        name={field}
        value={values[field]}
        error={touched[field] && errors[field] ? true : false}
        helperText={touched[field] && errors[field]}
        onChange={handleChange}
        // tslint:disable-next-line:jsx-no-lambda
        onKeyUp={(event: any) => {
          handleBlur(event) // (!) Important, workaround - Needed for detecting edited (touched) field even if changing field with CTRL.
        }}
        // tslint:disable-next-line:jsx-no-lambda
        onBlur={(e) => {
          handleBlur(e)
          if (touched[field]) {
            // Field has been edited (touched).
            if (field === 'zip') {
              this.fillOutCity(setFieldValue, `${values.zip}`)
            } else if (field === 'email') {
              formikProps.submitForm()
            }
          }
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">{fieldConf.icon}</InputAdornment>,
        }}
      />
    )
  }

  private handleCancel = () => {
    this.setState({ edit: false, cancelCount: this.state.cancelCount + 1 })
  }

  private handleSaveButton = async (formRecord: IFormValues) => {
    const { cancelCount, ...record } = formRecord
    const { onPatchCallback, customerIdentifier } = this.props

    this.setState({ loading: true })

    const { data } = await onPatchCallback(customerIdentifier, record)

    if (data) {
      this.setState({
        record: data,
        edit: false,
        loading: false,
        extSysCustomerUpdateMessage: data.extSysCustomerUpdateMessage,
        title: data.customerType === 'BUSINESS' ? data.companyName || data.name : data.name,
      })

      if (data!.extSysCustomerUpdateMessage) {
        notify.warning({
          message: t(
            'An error occurred in V4 request. You have to edit Customer info manually in V4 (super important)!',
          ),
        })
      }

      // Updates Update-customer-info here.
      this.props.onUpdateCustomerDetailsState && this.props.onUpdateCustomerDetailsState(data)
    } else {
      this.setState({ loading: false })
    }
  }

  private validate = (values: IFormValues) => {
    const { cancelCount, ...customer } = values
    return validateCustomer(customer, customer.customerType)
  }

  private handleEdit = () => {
    this.setState({ edit: true })
  }

  private fillOutCity = async (
    setFieldValue: (field: keyof IAdminCustomer & string, value: any, shouldValidate?: boolean) => void,
    zipcode: string,
  ) => {
    if (zipcode && zipcode.length > 0) {
      const zipCity = await getCityByZip(this.country, zipcode)
      if (zipCity) {
        setFieldValue('city', zipCity)
      }
    }
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles))(CustomerDetails)

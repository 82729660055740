import {
  Button,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Add, Remove } from '@material-ui/icons'
import { formatMileage } from '@omnicar/sam-format'
import { formatDate } from '@omnicar/sam-format/dist/formatDate/formatDate'
import {
  ContractState,
  ContractValueType,
  IContractBalanceStatistics,
  IContractDetailsRecord,
} from '@omnicar/sam-types'
import { initBalanceStatistics, TInitBalanceStatistics } from 'actions/contractSettlement'
import { withContractDisplayConfig } from 'components/ContractDisplayConfig/withContractDisplayConfig'
import { LayoutRow } from 'components/Mui/Layout'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'
import { ContractCalculationMethod } from 'types/contractFlow'
import { getCurrency } from 'utils/localStorage'
import { bindDispatch } from 'utils/redux'
import { formatToNumberWithCurrency, formatToNumberWithSign } from 'utils/regex'
import { Card } from '../../../../../components/Mui/Card'
import StatisticsCollapseItems from './StatisticsCollapseItems'
import StatisticsHeaderItem from './StatisticsHeaderItem'
import StatisticsItem, { IOwnProps as IStatistics } from './StatisticsItem'
import { IContractDisplayConfig } from 'reducers/contractDisplayConfig/initialState'

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    card: {
      height: '100%',
      backgroundColor: '#DFE5F0',
      flexBasis: '100%',
    },
    rootRow: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    shrinkIcon: {
      transform: 'rotate(180deg)',
      fontSize: spacing(2),
      paddingLeft: spacing(0.5),
    },
    expandIcon: {
      fontSize: spacing(2),
      paddingRight: spacing(0.5),
      transform: 'rotate(0deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    expandTitleText: {
      color: palette.primary.main,
      textDecoration: 'none',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      paddingBottom: '1px',
      fontSize: spacing(1.5),
    },
    collapseButton: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      padding: 0,
      marginTop: spacing(2),
      marginBottom: spacing(2),
    },
    cardContent: {
      height: '85%',
    },
    cardActions: {
      height: '5%',
      display: 'flex',
      justifyContent: 'center',
    },
    emptyCardTitle: {
      fontSize: spacing(3),
      color: palette.error.dark,
      textTransform: 'uppercase',
    },
    setAmountButton: {
      '&:hover': {
        backgroundColor: palette.secondary.main,
      },
      backgroundColor: palette.secondary.dark,
      marginTop: spacing(2),
      marginBottom: spacing(2),
      marginLeft: spacing(3),
    },
    setAmountTitleText: {
      color: 'white',
      fontWeight: 'bold',
      textDecoration: 'none',
      fontSize: spacing(1.75),
    },
    emptyCard: {
      paddingTop: spacing(5),
      backgroundColor: '#F4D1D7',
      height: '100%',
    },
    dialog: {
      width: '50%',
      marginLeft: '50%',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
    dialogTitle: {
      fontSize: spacing(2.5),
      color: palette.error.main,
      marginBottom: spacing(4),
    },
    confirmButton: {
      width: '20%',
      color: 'white',
      backgroundColor: palette.secondary.main,
      marginLeft: '40%',
    },
    dialogSubtitle: {
      marginTop: spacing(4),
      fontSize: spacing(2),
      color: palette.primary.main,
    },
  })

interface IOwnProps {
  id: string
  statistics: IContractBalanceStatistics
  contractDetails: IContractDetailsRecord
  valueType: ContractValueType
  isReadOnly?: boolean
  overCharge?: number
  underCharge?: number
  updateCounter: number
  setSettlementOperationAmount: (amount: number) => void
  isSettleTab: boolean
  isSettled: boolean
  contractState?: ContractState
}

interface IReduxDispatchProps {
  initBalanceStatistics: TInitBalanceStatistics
}

interface IState {
  expanded: boolean
  isPopupShown: boolean
}

type TProps = IOwnProps & WithStyles<typeof styles> & IReduxDispatchProps & IContractDisplayConfig

class ContractBalanceStatistics extends React.Component<TProps, IState> {
  public state = {
    expanded: false,
    isPopupShown: false,
  }

  public componentDidMount() {
    const { initBalanceStatistics, id } = this.props
    initBalanceStatistics(id)
    this.setState({ isPopupShown: this.checkWorkshopPopup() })
  }

  public componentDidUpdate(prevProps: TProps) {
    const { id, updateCounter, initBalanceStatistics, isSettleTab, statistics } = this.props
    const shouldUpdateData = id !== prevProps.id || updateCounter !== prevProps.updateCounter

    shouldUpdateData && initBalanceStatistics(id)
    ;(shouldUpdateData ||
      prevProps.isSettleTab !== isSettleTab ||
      prevProps.statistics.workshopCosts.price !== statistics.workshopCosts.price) &&
      this.setState({ isPopupShown: this.checkWorkshopPopup() })
  }

  public render() {
    const { classes, contractDetails, isReadOnly, setSettlementOperationAmount, contractState, hiddenVAT } = this.props
    const {
      settlementInvoices,
      calculationMethod,
      settlementInvoicesTotalSum,
      settlementInfo,
      underOverUnitsBalance,
      remainingCharge,
      remainingMonths,
    } = this.props.statistics
    const { expanded, isPopupShown } = this.state
    const showSettleInvoicesDetails = !Object.values(settlementInvoices).every((i) => i.priceInclVat === 0)
    const showStatistics = typeof contractDetails.settledUnits === 'number'
    const { renderSection, renderDivider } = this
    const showUseAmountButton =
      !!(underOverUnitsBalance || (calculationMethod === 200 && remainingMonths)) &&
      !settlementInfo &&
      !isReadOnly &&
      contractState !== ContractState.CancelledSubscription

    return (
      <AppContext.Consumer>
        {() => (
          <div>
            <LayoutRow columns={2}>
              <Card classes={{ root: classes.card }}>
                <CardContent classes={{ root: showStatistics ? classes.cardContent : classes.emptyCard }}>
                  {!showStatistics ? (
                    <Grid container justify="center">
                      <span className={classes.emptyCardTitle}>{t('Settled mileage is required for calculation')}</span>
                    </Grid>
                  ) : (
                    <>
                      <Table>
                        <TableHead>
                          <StatisticsHeaderItem text={t('Settlement contract overview')} />
                        </TableHead>
                        <TableBody>
                          {renderSection(this.createContractInfoSection())}
                          {renderDivider()}
                          {renderSection(this.createCloseInfoSection())}
                          {renderDivider()}
                          {renderSection(this.createContractBalanceSection())}
                          {renderDivider()}
                          {renderSection(this.createUnderOverUnitsSection())}

                          {showUseAmountButton && (
                            <Button
                              className={classes.setAmountButton}
                              onClick={() =>
                                setSettlementOperationAmount(
                                  (underOverUnitsBalance && underOverUnitsBalance.cost.priceInclVat) ||
                                    remainingCharge.priceInclVat,
                                )
                              }
                            >
                              <Typography variant="body1" className={classes.setAmountTitleText}>
                                {t('Use recommended amount')}
                              </Typography>
                            </Button>
                          )}
                          {showSettleInvoicesDetails && renderDivider()}
                          {showSettleInvoicesDetails &&
                            renderSection([
                              {
                                title: t('Settlement operations amount'),
                                titleNote: hiddenVAT ? undefined : `(${t('including VAT')})`,
                                value: formatToNumberWithCurrency(settlementInvoicesTotalSum.priceInclVat),
                                addValueColor: this.handleValueColor(settlementInvoicesTotalSum.priceInclVat),
                              },
                            ])}
                        </TableBody>
                      </Table>
                      <Dialog open={isPopupShown} className={classes.dialog}>
                        <DialogContent classes={{ root: classes.dialogContent }}>
                          <Typography variant="body1" className={classes.dialogTitle}>
                            {t('Are you sure?')}
                          </Typography>
                          <Button className={classes.confirmButton} onClick={this.closePopup}>
                            {t('Ok')}
                          </Button>
                          <Typography variant="body1" className={classes.dialogSubtitle}>
                            {t(
                              'Please note that the cost of the workshop is low. Please upload costs if you have forgotten before continuing',
                            )}
                          </Typography>
                        </DialogContent>
                      </Dialog>
                      <CardActions classes={{ root: classes.cardActions }}>
                        {showSettleInvoicesDetails && (
                          <Button
                            className={classes.collapseButton}
                            onClick={() => this.setState({ expanded: !expanded })}
                          >
                            {expanded ? (
                              <Remove className={classes.expandIcon} />
                            ) : (
                              <Add className={classes.shrinkIcon} />
                            )}
                            <Typography variant="body1" className={classes.expandTitleText}>
                              {expanded ? t('Hide details') : t('Show details')}
                            </Typography>
                          </Button>
                        )}
                      </CardActions>
                      {showSettleInvoicesDetails && (
                        <StatisticsCollapseItems expanded={expanded} settlementInvoices={settlementInvoices} />
                      )}
                    </>
                  )}
                </CardContent>
              </Card>
            </LayoutRow>
          </div>
        )}
      </AppContext.Consumer>
    )
  }

  private checkWorkshopPopup = () => {
    const { isSettled, contractDetails, statistics, isSettleTab, isReadOnly } = this.props
    const workshopCosts = statistics.workshopCosts.price
    const paidInvoicesTotalSum = statistics.paidInvoicesTotalSum.price
    const isAmountCorrect = paidInvoicesTotalSum / 2 <= workshopCosts

    return (
      !isSettled &&
      contractDetails.settledUnits !== undefined &&
      (!isAmountCorrect || !workshopCosts) &&
      isSettleTab &&
      !isReadOnly
    )
  }

  private renderDivider = () => (
    <TableRow>
      <TableCell colSpan={2}>
        <Divider />
      </TableCell>
    </TableRow>
  )

  private closePopup = () => this.setState({ isPopupShown: false })

  private renderSection = (items: IStatistics[]) =>
    items.map((i) => (
      <StatisticsItem
        title={i.title}
        value={i.value}
        details={i.details}
        tooltip={i.tooltip}
        addValueColor={i.addValueColor}
        titleNote={i.titleNote}
      />
    ))

  private createUnderOverUnitsSection = (): IStatistics[] => {
    const currency = getCurrency()
    const { overCharge, underCharge, contractState, hiddenVAT } = this.props

    if (contractState === ContractState.CancelledSubscription) {
      return []
    }

    const {
      underOverUnitsBalance,
      calculatedUnits,
      remainingMonths,
      remainingCharge,
      calculationMethod,
    } = this.props.statistics
    const prefix = underOverUnitsBalance
      ? this.getUnderOverMileagePrefix(underOverUnitsBalance.units)
      : t('Under / Over')
    const underMileageLimit: IStatistics | undefined =
      underOverUnitsBalance && underOverUnitsBalance.units < 0
        ? {
            title: t('Maximum under-mileage'),
            value: formatMileage(underOverUnitsBalance.underUnitsLimit, {
              symbolDisplayType: 'APPEND',
            }),
          }
        : undefined
    const remainingMonthsInfo: IStatistics = {
      title: t('Remaining amounts'),
      value: `${remainingMonths} ${t('months')}`,
    }
    const remainingAmountInfo: IStatistics = {
      title: t('Recommended extra charge'),
      titleNote: hiddenVAT ? undefined : `(${t('including VAT')})`,
      value: formatToNumberWithCurrency(remainingCharge.priceInclVat),
      addValueColor: this.handleValueColor(remainingCharge.priceInclVat),
    }
    const recommendedChargeInfo: IStatistics = {
      title: t('Recommended %payment', {
        payment: this.getPaymentType(underOverUnitsBalance?.units),
      }),
      titleNote: hiddenVAT ? undefined : `(${t('including VAT')})`,
      value: formatToNumberWithCurrency((underOverUnitsBalance && underOverUnitsBalance.cost.priceInclVat) || 0),
      addValueColor: this.handleValueColor(underOverUnitsBalance?.cost.priceInclVat),
    }
    const items: IStatistics[] = [
      {
        title: t('Permitted mileage driven'),
        value: formatMileage(calculatedUnits, { symbolDisplayType: 'APPEND' }),
      },
      {
        title: t('%prefix mileage', {
          prefix,
        }),
        value:
          (underOverUnitsBalance && formatMileage(underOverUnitsBalance.units, { symbolDisplayType: 'APPEND' })) ||
          'N/A',
      },
      {
        title: t('%prefix mileage fee', {
          prefix,
        }),
        value: underOverUnitsBalance
          ? `${
              underOverUnitsBalance.cost.price && (underOverUnitsBalance.units > 0 ? overCharge : underCharge)
            } ${currency}`
          : 'N/A',
      },
    ]

    underMileageLimit && items.push(underMileageLimit)

    if (calculationMethod === 200 && remainingMonths) {
      items.push(remainingMonthsInfo)
      items.push(remainingAmountInfo)
    } else {
      items.push(recommendedChargeInfo)
    }

    return items
  }

  private createContractBalanceSection = (): IStatistics[] => {
    const { contractDetails, hiddenVAT } = this.props
    const currency = getCurrency()
    const { paidInvoicesTotalSum, pendingInvoicesTotalSum, workshopCosts, contractBalance } = this.props.statistics
    const nonPaidInfo: IStatistics | undefined =
      pendingInvoicesTotalSum && pendingInvoicesTotalSum.price > 0
        ? {
            title: t('Total unpaid amount'),
            titleNote: hiddenVAT ? undefined : `(${t('including VAT')})`,
            value: formatToNumberWithCurrency(pendingInvoicesTotalSum.priceInclVat),
          }
        : undefined
    const items: IStatistics[] = [
      {
        title: t('Total price'),
        titleNote: hiddenVAT ? undefined : `(${t('including VAT')})`,
        value: formatToNumberWithCurrency(contractDetails.totalPrice.priceInclVat),
      },
      {
        title: t('Total turnover'),
        titleNote: `(${t('total paid bills')})`,
        value: formatToNumberWithCurrency(paidInvoicesTotalSum.priceInclVat),
      },
      {
        title: t('Total Workshop costs'),
        titleNote: hiddenVAT ? undefined : `(${t('excluding VAT')})`,
        value: `${formatToNumberWithSign(workshopCosts.price * -1)} ${currency}`,
      },
      {
        title: t('Current balance'),
        titleNote: hiddenVAT ? undefined : `(${t('excluding VAT')})`,
        value: `${formatToNumberWithSign(hiddenVAT ? workshopCosts.priceInclVat : contractBalance.price)} ${currency}`,
        addValueColor: this.handleValueColor(contractBalance.price),
      },
    ]

    nonPaidInfo && items.splice(1, 0, nonPaidInfo)

    return items
  }

  private handleValueColor = (amount?: number): boolean | undefined => (amount ? amount < 0 : undefined)

  private createContractInfoSection = (): IStatistics[] => {
    const { contractDetails, statistics } = this.props
    const {
      prettyIdentifierShort,
      contractName,
      contractType,
      duration,
      endMileage,
      startDate,
      expirationDate,
    } = contractDetails
    const calculationMethodTitle = t(
      (ContractCalculationMethod[statistics.calculationMethod] as 'FirstRegDate') || 'ContractCreationDate',
    )

    return [
      { title: t('Agreement'), value: prettyIdentifierShort },
      { title: t('Name'), value: contractName },
      { title: t('Contract Type'), value: contractType },
      { title: t('Calculation method'), value: calculationMethodTitle },
      {
        title: t('duration / mileage'),
        value: `${duration} ${t('months')} / ${formatMileage(endMileage, { symbolDisplayType: 'APPEND' })}`,
      },
      { title: t('start date / end date'), value: `${formatDate(startDate)} / ${formatDate(expirationDate)}` },
    ]
  }

  private createCloseInfoSection = (): IStatistics[] => {
    const { closingDate, remainingMonths, monthsAtClosing, underOverUnitsBalance } = this.props.statistics
    const { settledUnits } = this.props.contractDetails

    return [
      { title: t('Terminated / Expired date'), value: formatDate(closingDate) },
      { title: t('Closed before time'), value: remainingMonths ? t('YES') : t('NO') },
      {
        title: t('Real duration / mileage'),
        value: `${monthsAtClosing} ${t('months')} / ${formatMileage(settledUnits!, {
          symbolDisplayType: 'APPEND',
        })}`,
      },

      {
        title: t('status of settlement'),
        value: this.getSettlementStatus(),
        addValueColor: this.handleValueColor(underOverUnitsBalance?.units),
      },
    ]
  }

  private getUnderOverMileagePrefix = (units: number): string => (units > 0 ? t('Over') : t('Under'))

  private getPaymentType = (units: number | undefined): string => (!units || units > 0 ? t('charge') : t('credit'))

  private getSettlementStatus = (): string => {
    const { underOverUnitsBalance } = this.props.statistics

    return underOverUnitsBalance ? (underOverUnitsBalance.units > 0 ? t('Over mileage') : t('Under mileage')) : t('OK')
  }
}

export default compose<TProps, IOwnProps>(
  withContractDisplayConfig,
  withStyles(styles),
  connect(null, bindDispatch({ initBalanceStatistics })),
)(ContractBalanceStatistics)

import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { HelpOutline as HelpOutlineIcon, Input as InputIcon, ListAlt as ListAltIcon } from '@material-ui/icons'
import {
  IReportDefinition,
  IReportSearchRequest,
  IReportSearchResponse,
  IsoLocale,
  TContractObject,
} from '@omnicar/sam-types'
import { getSupportedlocales, reportExportUrl } from 'api/api'
import classNames from 'classnames'
import Typography from 'components/Typography'
import React, { ChangeEvent, Component } from 'react'
import { findDOMNode } from 'react-dom'
import { AppContext } from 'store/appContext'
import { theme } from 'theme'
import { t } from 'translations/translationFunctions'
import { isUseWhiteBGTheme } from 'utils/localStorage'
import { oneline } from 'utils/string'
import { getMappedTranslation } from 'utils/translation'
import { defaultLocales, IDisplayConfigs, reportColor } from './ReportConstants'

const styles = ({ spacing }: Theme) =>
  createStyles({
    headerSection: {
      top: 0,
      paddingTop: '10px',
      position: 'sticky',
      backgroundColor: reportColor.headerBgColor,
      paddingLeft: spacing(1),
      justifyContent: 'space-between',
    },
    buttonRow: {
      display: 'flex',
      flexWrap: 'nowrap',
    },
    button: {
      marginBottom: spacing(1),
      '&:not(:last-child)': {
        marginRight: spacing(1),
      },
    },
    buttonIcon: {
      fontSize: 16,
      marginRight: spacing(1),
      color: reportColor.buttonFgColor,
    },
    buttonText: {
      color: reportColor.buttonFgColor,
    },
    buttonPrimary: {
      backgroundColor: isUseWhiteBGTheme() ? reportColor.buttonColor : theme.palette.primary[500],
      '&:hover': {
        backgroundColor: isUseWhiteBGTheme() ? reportColor.labelColor : theme.palette.primary[700],
      },
    },
    icon: {
      marginRight: spacing(1),
      fontSize: 16,
    },
    fullWidth: {
      width: '100%',
    },
    rightCol: {
      textAlign: 'left',
      marginLeft: '1em',
      accentColor: theme.palette.secondary[500],
    },
    resizableBlock: {
      display: 'inline-block',
      overflowY: 'auto',
      overflowX: 'auto',
      width: 'auto',
      right: 'inherit',
    },
    scrollableBlock: {
      display: 'block',
    },
    helpIconAndTextContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    searchCategoryHeader: {
      paddingRight: '0.4em',
      paddingLeft: '0.6em',
      height: 'fit-content',
      width: 'fit-content',
    },
    groupByHeader: {
      paddingRight: '0.4em',
      paddingLeft: '0.6em',
      height: 'fit-content',
      width: 'fit-content',
      '&:not(:last-child)': {
        marginBottom: '5px',
      },
    },
    helpIcon: {
      fontSize: 18,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    tooltip: {
      maxWidth: '40em',
      maxHeight: '40em',
      paddingRight: '2em',
      overflowY: 'scroll',
      fontSize: 14,
      whiteSpace: 'pre-wrap',
    },
    tooltipHeader: {
      position: 'sticky',
      top: 0,
      marginLeft: '36em',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    hoverPointer: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    lightGray: {
      backgroundColor: 'lightGray',
    },
    padLeft: {
      paddingLeft: 0.8 * spacing(1),
    },
    closeIcon: {
      marginBottom: '5px',
    },
    flexRowContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    flexWrap: {
      flexWrap: 'wrap',
      textAlign: 'left',
    },
    searchLabel: {
      width: '142px',
    },
    floatLeft: {
      float: 'left',
    },
    generalConfig: {
      paddingLeft: 2 * spacing(1),
      '&:media(min-width:1027px)': {
        paddingLeft: '30px',
      },
    },
    smallButton: {
      height: '30px',
      width: 'fit-content',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    filterContainer: {
      maxWidth: '43vw',
    },
    checkboxLabel: {
      paddingLeft: '5px',
    },
    checkboxContainer: {
      paddingBottom: '10px',
    },
    reportHelpContent: {
      fontSize: 14,
    },
    helpButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      marginRight: '1rem',
    },
  })

interface IProps extends WithStyles<typeof styles> {
  reportDefinition: IReportDefinition | null
  reportRequest: IReportSearchRequest
  reportData: IReportSearchResponse | null
  moreReportsFetched: boolean
  searchId: number
  fetchingData: boolean
  fetchReport: () => Promise<void>
  setReportSearchRequest: (reportSearchRequest: IReportSearchRequest) => void
  setConfig: (vals: Partial<IDisplayConfigs>) => void
  displayConfigs: IDisplayConfigs
  buttonsPanelHeight: number
  onCloseWindow?: () => void
  disableResize: boolean
  setProductTypeRequest: (contractObjectType: TContractObject) => void
}

interface IState {
  windowHeight: number | null
  thisTop: number | null
  isTooltipOpen: boolean
  tooltipText: string
  showAllFilter: boolean
  showAllGroupBy: boolean
  isShowReportHelp: boolean
}

const contractObjectTypes: TContractObject[] = ['Vehicle', 'Product']

// ==========================================================================
// Main Component Start
// ==========================================================================

class ReportSearch extends Component<IProps, IState> {
  public state: IState = {
    windowHeight: null,
    thisTop: null,
    isTooltipOpen: false,
    tooltipText: '',
    showAllFilter: true,
    showAllGroupBy: true,
    isShowReportHelp: false,
  }

  componentDidMount() {
    this.getAvailbleLocales()
    this.updateWindowDimensions()
  }

  componentDidUpdate() {
    this.updateWindowDimensions()
  }

  public render() {
    return this.renderReportDefinition()
  }

  private updateWindowDimensions = () => {
    var element = findDOMNode(this)
    let offsetTop: number | null = null
    if (element instanceof HTMLElement) {
      const rect = element.getBoundingClientRect()
      offsetTop = rect.top + 500
    }
    if (window.innerHeight !== this.state.windowHeight || offsetTop !== this.state.thisTop) {
      this.setState({
        windowHeight: window.innerHeight,
        thisTop: offsetTop,
      })
    }
  }

  // ==========================================================================
  // Sub Rendering
  // ==========================================================================

  private renderReportDefinition = () => {
    const classes = this.props.classes
    const rd = this.props.reportDefinition
    if (!rd) return <div />

    const { isShowReportHelp } = this.state

    return (
      <div>
        {isShowReportHelp && this.renderReportHelpDialog()}
        <div className={classNames(classes.headerSection, classes.flexRowContainer)}>
          {this.renderHeader()} <div className={classes.helpButton}>{this.renderReportHelpButton()}</div>
        </div>
        <div className={classNames(classes.resizableBlock, classes.flexRowContainer, classes.flexWrap)}>
          <div className={classes.filterContainer}>
            {rd.columns.length && (
              <div>
                <div className={classNames(classes.helpIconAndTextContainer, classes.filterContainer)}>
                  <h3 className={classes.searchCategoryHeader}>{t('Filters')}</h3>
                  <div
                    className={classNames(classes.helpIconAndTextContainer, classes.hoverPointer, classes.smallButton)}
                    onClick={this.toggleAllFilter}
                  >
                    {!this.state.showAllFilter && (
                      <h3 className={classNames(classes.searchCategoryHeader, classes.lightGray)}>{t('Show All')}</h3>
                    )}
                    {this.state.showAllFilter && (
                      <h3 className={classNames(classes.searchCategoryHeader, classes.lightGray)}>{t('Hide Empty')}</h3>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div>{this.renderSearchField()}</div>
          </div>
          <div>
            {rd.groupByColumns.length > 0 && (
              <>
                <div className={classes.helpIconAndTextContainer}>
                  <h3 className={classes.groupByHeader}>{t('Group by column values')}</h3>
                  <HelpOutlineIcon
                    className={classes.helpIcon}
                    onClick={() =>
                      this.setState({ isTooltipOpen: true, tooltipText: tooltipTextForGroupByColumnValues })
                    }
                  />
                </div>
                {rd.groupByColumns.length > 5 && (
                  <div
                    className={classNames(classes.helpIconAndTextContainer, classes.hoverPointer, classes.smallButton)}
                    onClick={this.toggleAllGroups}
                  >
                    {!this.state.showAllGroupBy ? (
                      <h3 className={classNames(classes.groupByHeader, classes.lightGray)}>{t('Show All')}</h3>
                    ) : (
                      <h3 className={classNames(classes.groupByHeader, classes.lightGray)}>{t('Hide Empty')}</h3>
                    )}
                  </div>
                )}
              </>
            )}
            {this.renderGroupByColumns()}
          </div>
          <div className={classes.generalConfig}>
            <h3 className={classes.searchCategoryHeader}>{t('General Configs')}</h3>
            {this.renderGeneralConfig()}
          </div>
        </div>
      </div>
    )
  }

  private getAvailbleLocales = async () => {
    const { localeFormats } = this.props.displayConfigs
    if (!Object.keys(localeFormats).length) {
      const res = await getSupportedlocales()
      if (res.data) {
        this.props.setConfig({ localeFormats: res.data })
      } else {
        this.props.setConfig({ localeFormats: defaultLocales })
      }
    }
  }

  private toggleAllFilter = () => this.setState({ showAllFilter: !this.state.showAllFilter })
  private toggleAllGroups = () => this.setState({ showAllGroupBy: !this.state.showAllGroupBy })

  private renderHeader = () => {
    const { classes } = this.props
    const rd = this.props.reportDefinition!

    return (
      <span className={classes.headerSection}>
        {/* TODO: Reorganize components so the header section is not
        in the scrollable part, then we can remove this marginTop:0  */}
        <h2 style={{ marginTop: 0 }}>{getMappedTranslation(rd.descriptionMap || {}, rd.defaultDescription)}</h2>
        <div className={classes.buttonRow}>
          <Button
            disabled={this.props.fetchingData}
            className={classNames(classes.button, classes.buttonPrimary)}
            color="secondary"
            variant="contained"
            href={reportExportUrl({ format: 'xls', req: this.props.reportRequest })}
            data-e2e="reportDownload-xls-btn"
          >
            <InputIcon className={classes.icon} />
            {t('XLS')}
          </Button>
          <Button
            disabled={this.props.fetchingData}
            className={classNames(classes.button, classes.buttonPrimary)}
            color="secondary"
            variant="contained"
            href={reportExportUrl({ format: 'csv', req: this.props.reportRequest })}
            data-e2e="reportDownload-csv-btn"
          >
            <InputIcon className={classes.icon} />
            {'CSV'}
          </Button>
          <Button
            disabled={this.props.fetchingData}
            variant="contained"
            className={classNames(classes.button, classes.buttonPrimary)}
            // tslint:disable-next-line jsx-no-lambda
            onClick={() => {
              this.props.fetchReport()
              this.setState({ isTooltipOpen: false })
            }}
            data-e2e={`AdministrationPage__Reports__fetch`}
          >
            <ListAltIcon className={classes.buttonIcon} />
            <Typography variant="button" className={classes.buttonText}>
              {'Tab'}
            </Typography>
          </Button>
        </div>
      </span>
    )
  }

  private renderGeneralConfig = () => {
    const { classes } = this.props
    const { localeFormats } = this.props.displayConfigs
    return (
      <React.Fragment>
        <table className={classNames(classes.fullWidth, classes.padLeft)}>
          <tbody>
            <tr>
              <td>{t('Show row numbers')}</td>
              <td className={classes.rightCol}>
                <input
                  className={this.props.classes.rightCol}
                  checked={this.props.displayConfigs.showRownoCol}
                  type="checkbox"
                  id={`chk-showrowno`}
                  onChange={this.showRowNoChanged}
                />
              </td>
            </tr>
            <tr>
              <td>{t('Show links')}</td>
              <td className={classes.rightCol}>
                <input
                  className={this.props.classes.rightCol}
                  checked={this.props.displayConfigs.showLinks}
                  type="checkbox"
                  id={`chk-showlinks`}
                  onChange={this.showLinksChanged}
                />
              </td>
            </tr>
            <AppContext.Consumer>
              {({ isSuperAdmin }) =>
                true && (
                  <tr>
                    <td>{t('Date formatting')}</td>
                    <td className={classes.rightCol}>
                      <div>
                        <select onChange={this.formatLocaleChanged} value={this.props.displayConfigs.locale}>
                          <option value="">{t('default')}</option>
                          {Object.keys(localeFormats).map((locale, index) => (
                            <option key={index} value={locale}>
                              {locale}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                  </tr>
                )
              }
            </AppContext.Consumer>
          </tbody>
        </table>
      </React.Fragment>
    )
  }

  private showLinksChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    this.props.setConfig({ showLinks: checked })
  }

  private showRowNoChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    this.props.setConfig({ showRownoCol: checked })
  }

  private formatLocaleChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    this.props.setConfig({ locale: value as IsoLocale })
  }

  private renderSearchField = () => {
    const rd = this.props.reportDefinition
    const { classes } = this.props
    const { contractObjectType } = this.props.reportRequest

    if (!rd) {
      return <div /> // Should never happen
    }

    const requestSearchArray = this.props.reportRequest.search
    const requestSearchValues = new Map<string, string>()

    // Index search-values from props in a Map, so later we can retrieve them for input-field.
    requestSearchArray.forEach((item) => {
      requestSearchValues.set(item.col, item.search)
    })

    const arVisibleItems = this.state.showAllFilter
      ? rd.columns.filter((item) => !item['groupOnly']).map((col) => col.name)
      : requestSearchArray.filter((item) => item.search && item.search.trim()).map((item) => item.col)
    const visibleItems = new Set(arVisibleItems)

    return (
      <>
        {rd.hasProductFields &&
          contractObjectTypes.map((type) => (
            <span key={`checkbox-${type}`}>
              <input
                className={classes.rightCol}
                type="radio"
                id={`chk-${type}`}
                name={type}
                value={type}
                onChange={this.contractObjectTypeBoxChanged}
                checked={contractObjectType === type}
              />
              <label className={classes.checkboxLabel} htmlFor={type}>
                {t(type)}
              </label>
            </span>
          ))}
        <table>
          <tbody className={classNames(classes.flexRowContainer, classes.flexWrap)}>
            {rd.columns
              .filter((col) => visibleItems.has(col.name))
              .map((col) => {
                const { descriptionMap, description, name } = col
                return (
                  <tr key={name}>
                    <td className={classNames(classes.searchLabel, classes.padLeft)}>
                      {getMappedTranslation(descriptionMap || {}, description || name)}
                    </td>
                    <td>
                      <input
                        className={this.props.classes.rightCol}
                        name={name}
                        onKeyDown={this.onSearchKeyDown}
                        onChange={this.searchBoxChanged}
                        value={requestSearchValues.get(name) || ''}
                      />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </>
    )
  }

  private renderReportHelpButton = () => {
    const { classes } = this.props

    return (
      <Button
        className={classNames(classes.button, classes.buttonPrimary)}
        onClick={() => {
          this.setState({ isShowReportHelp: true })
        }}
        variant="contained"
        data-e2e={'Reports__report-help-button'}
      >
        <HelpOutlineIcon className={classes.buttonIcon} />
        <Typography variant="button" className={classes.buttonText}>
          {'Help'}
        </Typography>
      </Button>
    )
  }

  private renderReportHelpDialog = () => {
    const { classes } = this.props

    return (
      <Dialog open={true} maxWidth="xs" onClose={() => this.setState({ isShowReportHelp: false })}>
        <DialogTitle>Report Help</DialogTitle>

        <DialogContent>
          <div className={classes.reportHelpContent} dangerouslySetInnerHTML={{ __html: htmlReportHelpText }} />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => this.setState({ isShowReportHelp: false })} variant="contained" color="secondary">
            {t('Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private renderGroupByColumns = () => {
    const rd = this.props.reportDefinition
    if (!rd) return <div /> // Should never happen

    const { groupBy } = this.props.reportRequest
    const gbCheckedSet = new Set<string>(groupBy)
    const visibleItems = new Set(
      rd.groupByColumns.filter((colname) => this.state.showAllGroupBy || gbCheckedSet.has(colname)),
    )

    const cols = rd.columns
    return (
      <table>
        <tbody>
          {rd.groupByColumns
            .filter((colname) => visibleItems.has(colname))
            .map((colname) => {
              const coldef = cols.find((c) => c.name === colname)
              const description = (coldef && coldef.description) || colname
              const descriptionMap = (coldef && coldef.descriptionMap) || {}
              return (
                <tr key={`chk-${colname}`}>
                  <td>
                    <label htmlFor={`chk-${colname}`}>{getMappedTranslation(descriptionMap, description)}</label>
                  </td>
                  <td>
                    <input
                      className={this.props.classes.rightCol}
                      type="checkbox"
                      id={`chk-${colname}`}
                      name={colname}
                      value={colname}
                      onChange={this.groupByBoxChanged}
                      checked={gbCheckedSet.has(colname)}
                    />
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    )
  }

  // ==========================================================================
  // Action Handlers
  // ==========================================================================

  private searchBoxChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const reportRequest = this.props.reportRequest
    const item = reportRequest.search.find((s) => s.col === name)

    if (item) {
      item.search = value
    } else {
      reportRequest.search.push({ col: name, search: value })
    }

    this.props.setReportSearchRequest(reportRequest)
  }

  private contractObjectTypeBoxChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target
    const reportRequest = this.props.reportRequest
    const current = reportRequest.contractObjectType

    if (checked && current !== name) {
      this.props.setProductTypeRequest(name as TContractObject)
    }
  }

  private onSearchKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      await this.props.fetchReport()
    }
  }

  private groupByBoxChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target
    const reportRequest = this.props.reportRequest
    const item = reportRequest.groupBy.find((s) => s === name)

    if (checked && !item) {
      reportRequest.groupBy.push(name)
    } else if (!checked && item) {
      reportRequest.groupBy = reportRequest.groupBy.filter((s) => s !== name)
    }
    this.props.setReportSearchRequest(reportRequest)
  }
}

// ==========================================================================
// Tooltip texts
// ==========================================================================

/**
 * This evil junk of html is generated from ./doc/filterExpressions.md
 * Probably we'd want to use a module (maybe https://www.npmjs.com/package/react-markdown)
 * that could render the text directly from the .md file,
 * - easier to maintain,
 * - easier to handle different different versions,
 * - we don't need dangerouslySetInnerHTML
 */
const htmlReportHelpText: string = `
<h2 id="filter-expressions">Filter expressions</h2>
The simplest use of a filters/searches is just to enter the value you are searching for.<br>
A search value can start with one of the following symbols, with their normal meaning:<br/>
<strong>&lt;=</strong>, <strong>&gt;=</strong>, <strong>=</strong>, <strong>&gt;</strong>, <strong>&lt;</strong><br/>
<strong>=</strong> alone in a field means <strong>only empty values</strong>.<br>
<strong>&gt;</strong> alone in a field means <strong>values that are not empty</strong>.<br>
<strong>; (Semicolon)</strong> may be used to fine several values to find, for example to find all contracts belonging to either of 2 different contract types write (the start of) each value to fiilter on and separate with semicolon
<h3 id="numbers">Numbers</h3>
<strong>, (Comma)</strong> and <strong>. (Period)</strong> can both be used as decimal separators for numbers.<br>
Thousand separator shall not be entered in search fields for numbers, to avoid confuision with decimal seaparators.
<h3 id="texts">Texts</h3>
A normal text search without special characters will find all values that <strong>start with</strong> the the text in the search field - so to find all <em><strong>Citroën</strong></em> it would be enough just to write <em><strong>citr</strong></em><br>
<strong>%</strong> is a special character and is used as wild card<br>
<strong>=</strong> At the beginning of a text, means that the rest of the text must look exactly as written.
<h3 id="dates">Dates</h3>
A date in the search field can be written in several ways (the example shows 31’st of January 2021):
<ul>
<li><strong>210131</strong> (6 digits, fast and easy to type)</li>
<li><strong>2021-01-31</strong> (Swedish/International format)</li>
<li><strong>31.01.2021</strong> (Danish/Finnish/Norwegian format)</li>
<li><strong>31/01/2021</strong> (UK format)</li>
</ul>
Two dashes ( <strong>–</strong> ) may be used for time periods:
<ul>
<li><strong>16.01.2021 – 14.02.2021</strong></li>
</ul>
Or you can find all dates in a year or month like this:
<ul>
<li><strong>2021</strong> All dates in the year 2021</li>
<li><strong>2021-01</strong> All dates in January 2021</li>
</ul>
`

const tooltipTextForGroupByColumnValues: string = `${oneline(`
Merges all rows with the same value in checked columns to single rows,
and in numeric columns shows the sum of the values of the merged rows.
If one or more columns are checked then only checked columns and numeric columns are shown`)}

For example:
${oneline(`If 'brand' was selected and the brand Volvo normally would have shown five results
that each have their own unique data, then only show one row for the brand Volvo and in the
numeric columns show the sum of all then values in that column for Volvo rows`)}`

export default withStyles(styles)(ReportSearch)

import { Card, CardContent } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { WarningSharp as FailureIcon, CheckCircle as SuccessIcon } from '@material-ui/icons'
import { formatCurrency, formatDate } from '@omnicar/sam-format'
import { IChartInvoiceValue } from '@omnicar/sam-types'
import { withContractDisplayConfig } from 'components/ContractDisplayConfig/withContractDisplayConfig'
import Typography from 'components/Typography'
import React, { SFC } from 'react'
import { compose } from 'recompose'
import { AppContext } from 'store/appContext'
import { theme } from 'theme'
import { t } from 'translations/translationFunctions'
import { getPaymentFailureReasonDescription } from 'utils/contract'
import { formatYearMonthDate } from 'utils/date'
import { getCurrency } from 'utils/localStorage'
import { IContractDisplayConfig } from 'reducers/contractDisplayConfig/initialState'

interface IOwnProps {
  value: IChartInvoiceValue
}

type TProps = IOwnProps & WithStyles<typeof styles> & IContractDisplayConfig

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    tooltip: {
      display: 'flex',
      position: 'relative',
      maxWidth: '300px',
    },
    expectedValueHeading: {
      marginTop: spacing(2),
    },
    expectedValueAmount: {
      marginBottom: spacing(2),
    },
    exVat: {
      textAlign: 'right',
      color: theme.palette.text.light,
      fontStyle: 'italic',
      lineHeight: 1,
      position: 'absolute',
      right: spacing(1),
      bottom: spacing(1),
    },
    icon: { marginRight: spacing(1) },
    details: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: spacing(2),
    },
    paidDate: {
      color: palette.primary.main,
    },
    failureReason: {
      color: palette.error.main,
    },
  })

const PaymentChartInvoiceTooltip: SFC<TProps> = ({ classes, value, hiddenVAT }) => {
  const { tooltipData, x: date, y: amount } = value
  const { state, failureReason, successfulPaymentDate } = tooltipData
  const isUnpaid = state === 1

  return (
    <AppContext.Consumer>
      {({ locale }) => (
        <Card className={classes.tooltip}>
          <CardContent>
            <Typography variant="subheading">{formatYearMonthDate(date, locale)}</Typography>
            <div className={classes.details}>
              <div className={classes.icon}>
                {isUnpaid ? <FailureIcon color="error" /> : <SuccessIcon color="primary" />}
              </div>
              {isUnpaid && failureReason && (
                <Typography variant="body2" className={classes.failureReason}>
                  {getPaymentFailureReasonDescription(failureReason.paymentFailureReason)}
                </Typography>
              )}
              {!isUnpaid && successfulPaymentDate && (
                <Typography variant="body2" className={classes.paidDate}>{`${t('Paid')}: ${formatDate(
                  successfulPaymentDate,
                )}`}</Typography>
              )}
            </div>

            <Typography variant="body1" className={classes.expectedValueHeading}>
              {`${t('Value')}:`}
            </Typography>
            <Typography variant="title" className={classes.expectedValueAmount}>
              {formatCurrency(amount, { showDecimals: true })}
            </Typography>
            <Typography className={classes.exVat} variant="body2">
              {`${getCurrency()} ${hiddenVAT ? '' : t('ex. VAT')}`}
            </Typography>
          </CardContent>
        </Card>
      )}
    </AppContext.Consumer>
  )
}

export default compose<TProps, IOwnProps>(withContractDisplayConfig, withStyles(styles))(PaymentChartInvoiceTooltip)

import { CircularProgress, InputAdornment, TextField } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import {
  CalendarToday as CalendarTodayIcon,
  Storage as CylinderVolumeIcon, // Database icon would be nice for this, but it doesn't (yet?) exist.
  DirectionsCar as DirectionsCarIcon,
  Edit as EditIcon,
  Extension as ExtensionIcon,
  LocalGasStation as LocalGasStationIcon,
  Power as PowerIcon,
  Commute as TypeIcon,
} from '@material-ui/icons'
import { formatDate } from '@omnicar/sam-format'
import { Vehicle } from '@omnicar/sam-types'
import classNames from 'classnames'
import Typography from 'components/Typography'
import React, { ChangeEvent } from 'react'
import { compose } from 'recompose'
import { createPanelStyles } from 'theme'
import { t } from 'translations/translationFunctions'
import { REQUIRED_VIN_LENGTH } from 'utils/regex'
import { localFuelTypeName } from 'utils/vehicle'

interface IOwnProps {
  forbidEditingVIN?: boolean
  record: Vehicle
  loading: boolean
  loaded?: boolean
  initData: () => void
  onChange: (record: Vehicle, error?: { vin?: string; regNumberNumber?: string }) => void
  editing: boolean
  error: {
    vin?: string
    regNumber?: string
  }
  extSysRegNumberUpdateMessage?: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  loading: boolean
  loaded: boolean
  title: string
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    button: {
      marginLeft: theme.spacing(2),
    },
    errorColor: {
      color: theme.palette.error.main,
    },
    regNumber: {
      verticalAlign: 'super',
    },
    additionalDetailsContainer: {
      margin: '-6px',
      padding: '6px',
    },
    container: {
      display: 'flex',
      gap: '3rem',
    },
    cardContentTitleRow: {
      height: '5rem',
    },
  })

class VehicleDetails extends React.Component<TProps, IState> {
  public state: IState = {
    loading: false,
    loaded: this.props.loaded!,
    title: this.props.record.regNumber || '',
  }

  public render() {
    const { classes, loading, forbidEditingVIN, editing, record, error } = this.props
    const { title } = this.state

    const allowEditVin = !forbidEditingVIN

    return (
      <>
        <div className={classes.cardContentTitleRow}>
          <Typography className={classes.cardTitle} variant="title">
            <span title={t('Registration Number')}>
              <DirectionsCarIcon className={classes.cardTitleIcon} />
              <span className={classNames(classes.cardTitleText, classes.regNumber)}>{record.regNumber || title}</span>
            </span>
          </Typography>
          {loading && <CircularProgress size={20} color="secondary" />}
        </div>
        {!editing ? (
          <div className={classes.container}>
            <div>
              <div className={classes.presentationField} title={t('VIN')}>
                <EditIcon className={classes.presentationIcon} />
                {record.vin}
              </div>
              <div className={classes.presentationField} title={t('Registration Date')}>
                <CalendarTodayIcon className={classes.presentationIcon} />
                {record.regDate ? formatDate(new Date(record.regDate)) : ''}
              </div>
              <div className={classes.presentationField} title={t('Model')}>
                <ExtensionIcon className={classes.presentationIcon} />
                {record.brand.name} {record.model.name}
              </div>
              <div className={classes.presentationField} title={t('Fuel Type')}>
                <LocalGasStationIcon className={classes.presentationIcon} />
                {localFuelTypeName(record)}
              </div>
            </div>
            <div>
              <div className={classes.additionalDetailsContainer}>
                <div className={classes.presentationField} title={t('Vehicle type')}>
                  <TypeIcon className={classes.presentationIcon} />
                  {!record.vehicleType ? 'n/a' : `${record.vehicleType}`}
                </div>
                <div className={classes.presentationField} title={t('Engine max power')}>
                  <PowerIcon className={classes.presentationIcon} />
                  {!record.engineMaxPower ? 'n/a' : `${record.engineMaxPower} kW`}
                </div>
                <div className={classes.presentationField} title={t('Cylinder volume')}>
                  <CylinderVolumeIcon className={classes.presentationIcon} />
                  {!record.cylinderVolume ? 'n/a' : `${record.cylinderVolume}`}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <TextField
              className={classes.inputField}
              margin="dense"
              fullWidth={true}
              label={t('License Plate')}
              name="regNumber"
              onChange={this.handleChange}
              type="text"
              value={record.regNumber}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DirectionsCarIcon className={classes.inputIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputField}
              margin="dense"
              fullWidth={true}
              label={t('VIN')}
              name="vin"
              onChange={this.handleChange}
              type="text"
              error={error.vin ? true : false}
              helperText={error.vin}
              value={record.vin}
              disabled={!allowEditVin}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EditIcon className={classNames(classes.inputIcon, error.vin && classes.errorColor)} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputField}
              margin="dense"
              fullWidth={true}
              label={t('Registration Date')}
              name="regDate"
              onChange={this.handleChange}
              type="text"
              value={record.regDate ? formatDate(new Date(record.regDate)) : ''}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarTodayIcon className={classes.inputIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputField}
              margin="dense"
              fullWidth={true}
              label={t('Brand')}
              name="brand.name"
              onChange={this.handleChange}
              type="text"
              value={record.brand.name}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ExtensionIcon className={classes.inputIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputField}
              margin="dense"
              fullWidth={true}
              label={t('Model')}
              name="model.name"
              onChange={this.handleChange}
              type="text"
              value={record.model.name}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ExtensionIcon className={classes.inputIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputField}
              margin="dense"
              fullWidth={true}
              label={t('Fuel Type')}
              name="fuelType.name"
              onChange={this.handleChange}
              type="text"
              value={localFuelTypeName(record)}
              disabled={true} // If at some occasion this field is no longer disabled we must handdle localFuelTypeName
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalGasStationIcon className={classes.inputIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
      </>
    )
  }

  private handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = name === 'vin' || name === 'regNumber' ? e.target.value.toUpperCase() : e.target.value
    const error: Pick<TProps['error'], 'vin' | 'regNumber'> = {
      vin: undefined,
      regNumber: undefined,
    }

    if (name === 'vin' && value.length !== REQUIRED_VIN_LENGTH) {
      error.vin = t('Invalid vin number')
    }

    const record = {
      ...this.props.record,
      [name]: value,
    }

    this.props.onChange(record, error)
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles))(VehicleDetails)

import { Card, CircularProgress, createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import { ContractState, ContractValueType, IMileageChart, IPaymentChart } from '@omnicar/sam-types'
import { IValueChart } from '@omnicar/sam-types/types/admin/chart/contractCharts'
import * as api from 'api/api'
import React from 'react'
import { compose } from 'recompose'
import { valueTypeTranslations } from 'translations/ValueTypeTranslations'
import notify from 'utils/notify/notify'
import PaymentsChart from './PaymentsChart'
import SelectableTabs from './SelectableTabs'
import ValueChart from './ValueChart'

interface IOwnProps {
  id: string
  updateCounter: number
  valueType: ContractValueType | undefined
  startValueType: ContractValueType | undefined
  fromReportLink?: boolean
  serviceCount?: number
  settledUnits?: number | null
  contractState: ContractState
}

type TProps = IOwnProps & WithStyles<typeof styles>

export type TTab = 'payments' | 'value'

interface IState {
  activeTab: TTab
  paymentChartData: IPaymentChart | undefined
  mileageChartData: IMileageChart | undefined
  valueChartData: IValueChart | undefined
  loading: boolean
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      borderRadius: 10,
    },
    tabs: {
      width: '35%',
      display: 'flex',
    },
    content: {
      width: '65%',
      padding: spacing(2),
    },
    spinner: {
      position: 'absolute',
      top: spacing(2),
      right: spacing(2),
    },
  })

class ContractDetailsEconomyOverview extends React.Component<TProps, IState> {
  public state: IState = {
    activeTab: 'payments',
    paymentChartData: undefined,
    mileageChartData: undefined,
    valueChartData: undefined,
    loading: false,
  }
  public componentDidMount() {
    this.loadData()
  }

  public componentDidUpdate(prevProps: TProps) {
    if (prevProps.updateCounter < this.props.updateCounter) {
      this.loadData()
    }
  }

  public render() {
    const { classes, startValueType, valueType, serviceCount, settledUnits, contractState } = this.props
    const { activeTab, paymentChartData, mileageChartData, valueChartData, loading } = this.state

    const type = startValueType || valueType || 'Hours'
    const translations = type !== 'Services' ? valueTypeTranslations[type] : valueTypeTranslations['Hours']

    return (
      <Card className={classes.root}>
        {loading && <CircularProgress className={classes.spinner} size={16} color="secondary" />}
        <div className={classes.tabs}>
          <SelectableTabs
            data={{ paymentChartData, mileageChartData, valueChartData }}
            translations={translations}
            activeTab={activeTab}
            onTabSelect={this.handleTabSelect}
            valueType={type}
            serviceCount={serviceCount}
            settledUnits={settledUnits}
          />
        </div>
        <div className={classes.content}>
          {activeTab === 'payments' && paymentChartData && (
            <PaymentsChart data={paymentChartData} contractState={contractState} />
          )}
          {activeTab === 'value' && valueChartData && <ValueChart data={valueChartData} translations={translations} />}
        </div>
      </Card>
    )
  }

  private handleTabSelect = (activeTab: TTab) => {
    this.setState({ activeTab })
  }

  private loadData = async () => {
    this.setState({ loading: true })
    const res = await (this.props.fromReportLink
      ? api.getContractChartFromReportLink(this.props.id)
      : api.getContractDetailsChartData(this.props.id))

    this.setState({ loading: false })
    if (res.errorData || res.networkError) {
      notify.error()
    } else if (res.data) {
      const { paymentChart, mileageChart, valueChart } = res.data
      this.setState({ paymentChartData: paymentChart, mileageChartData: mileageChart, valueChartData: valueChart })
    }
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles))(ContractDetailsEconomyOverview)
